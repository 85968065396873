import { fetchJSON } from '../utils/fetchJSON';
import { getToken } from '../utils/cookieToken';

export const getAllEmployeeService = (params = {}) => {
	const {
		page,
		size,
		testState,
		testId
	} = params;

	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON(`/api/employee?
		page=${page || 1}&
		size=${size || 15}
		${testState ? `&testState=${testState}` : ''}
		${testId ? `&testId=${testId}` : ''}`, parameters);
};

export const getSkillsFilterService = () => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON('/api/employee/filter', parameters);
};

export const findSkillsFilterService = ({ data, params = {} }) => {
	const {
		page,
		size,
		testState,
		sort,
		order
	} = params;

	const parameters = {
		method: 'POST',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	};

	return fetchJSON(`/api/employee/filter/find?
		page=${page || 1}&
		size=${size || 15}
		${sort ? `&sort=${sort}` : ''}
		${order ? `&order=${order}` : ''}
		${testState ? `&testState=${testState}` : ''}`, parameters);
};

export const findIdSkillsFilterService = (id) => {
	const parameters = {
		method: 'POST',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON(`/api/employee/filter/find/${id}`, parameters);
};

// TODO: запрос на создание фильтра присутсвует в folder /ajax, убрать обработку тут
export const createSkillsFilterService = (data) => {
	const parameters = {
		method: 'POST',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	};

	return fetchJSON('/api/employee/filter', parameters);
};

export const updateSkillsFilterService = (id) => {
	const parameters = {
		method: 'PUT',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON(`/api/employee/filter/${id}`, parameters);
};

export const deleteSkillsFilterService = (id) => {
	const parameters = {
		method: 'DELETE',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON(`/api/employee/filter/${id}`, parameters);
};

export const getAllSkillsService = () => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON('/api/client/reference/skill', parameters);
};
