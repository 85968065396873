import { fetchJSON } from '../utils/fetchJSON';
import { getToken } from '../utils/cookieToken';


// <---- Paypal payment system with auth
export const completePaypalOrderService = (data) => {
	// payer_id and payment_id
	const { payload } = data;
	const parameters = {
		method: 'POST',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	};
	// /api/account/license/complete
	return fetchJSON('/api/order/paypal/complete/payment', parameters);
};

export const cancelPaypalOrderService = (data) => {
	// payer_id and payment_id
	const { payload } = data;
	const parameters = {
		method: 'POST',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	};

	return fetchJSON('/api/order/paypal/cancel/payment', parameters);
};

export const createPaypalOrderService = (data) => {
	const { payload } = data;
	const parameters = {
		method: 'POST',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	};

	return fetchJSON('/api/order/paypal/make/order', parameters);
};

// ----> Paypal payment system with auth

// <---- Paypal payment system without auth

export const completePaypalOrderWithoutAuthService = (data) => {
	// payer_id and payment_id
	console.log(data);
	const { payload } = data;
	const parameters = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	};

	return fetchJSON('/api/account/license/complete', parameters);
};

export const cancelPaypalOrderWithoutAuthService = (data) => {
	// payer_id and payment_id
	const { payload } = data;
	const parameters = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	};

	return fetchJSON('/api/account/license/cancel', parameters);
};

export const createPaypalOrderWithoutAuthService = (data) => {
	const { payload } = data;
	const parameters = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	};

	return fetchJSON('/api/account/license/create', parameters);
};

// ----> Paypal payment system without auth

export const checkPromoCodeService = ({ payload }) => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		}
	};

	return fetchJSON(`/api/coupons?code=${payload}`, parameters);
};

// <---- Stripe payment service  with auth

export const completeStripeOrderService = (data) => {
	const { payload } = data;
	const parameters = {
		method: 'POST',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	};

	return fetchJSON('/api/order/stripe/complete/payment', parameters);
};

export const cancelStripeOrderService = (data) => {
	const { payload } = data;
	const parameters = {
		method: 'POST',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	};

	return fetchJSON('/api/order/stripe/cancel/payment', parameters);
};

export const createStripeOrderService = (data) => {
	const { payload } = data;
	const parameters = {
		method: 'POST',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	};

	return fetchJSON('/api/order/stripe/make/order', parameters);
};

// ----> Stripe payment service with auth

// <---- Stripe payment service without auth

export const completeStripeOrderWithoutAuthService = (data) => {
	const { payload } = data;
	console.log(data);
	const parameters = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	};

	return fetchJSON('/api/account/license/stripe/complete', parameters);
};

export const cancelStripeOrderWithoutAuthService = (data) => {
	const { payload } = data;
	const parameters = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	};

	return fetchJSON('/api/account/license/stripe/cancel', parameters);
};

export const createStripeOrderWithoutAuthService = (data) => {
	const { payload } = data;
	const parameters = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	};

	return fetchJSON('/api/account/license/stripe/create', parameters);
};

// ----> Stripe payment service without auth

export const getStripeConfigService = () => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken()
		}
	};

	return fetchJSON('/api/order/stripe/config', parameters);
};
