import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import apiError from '../reducers/middleware/apiError';
import rootSaga from '../sagas';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware, apiError];

const store = createStore(rootReducer, composeEnhancers(
	applyMiddleware(...middleware)
));

sagaMiddleware.run(rootSaga);

export default store;
