export const errorCodesGeneral = {
	422: {
		message: 'Invalid request',
		translation_key: 'error.code.422'
	},
};

export const errorCodesRegistration = {
	409: {
		message: 'Email already used.',
		translation_key: 'error.code.register.409'
	},
	406: {
		message: 'Token is invalid.',
		translation_key: 'error.code.register.406'
	},
	422: {
		message: 'Invalid request',
		translation_key: 'error.code.422'
	},
};

export const errorCodesLogin = {
	400: {
		message: 'Wrong password.',
		translation_key: 'error.code.login.400'
	},
	404: {
		message: 'User not found',
		translation_key: 'error.code.login.404'
	},
	409: {
		message: 'User not active.',
		translation_key: 'error.code.login.409'
	},
	422: {
		message: 'Invalid request',
		translation_key: 'error.code.422'
	},
};

export const errorCodesForgot = {
	404: {
		message: 'Account not found.',
		translation_key: 'error.code.forgot.404'
	},
	406: {
		message: 'Token is invalid.',
		translation_key: 'error.code.forgot.406'
	},
	422: {
		message: 'Invalid request',
		translation_key: 'error.code.422'
	},
};

export const errorCodesChangePswd = {
	406: {
		message: 'Wrong current password',
		translation_key: 'error.code.change.406'
	},
	422: {
		message: 'Invalid request',
		translation_key: 'error.code.422'
	},
};

export const errorCodesEmployee = {
	404: {
		message: 'Employee not found',
		translation_key: 'error.code.employee.404'
	},
	409: {
		message: 'Employee already exist.',
		translation_key: 'error.code.employee.409'
	},
	422: {
		message: 'Invalid request',
		translation_key: 'error.code.422'
	},
	500: {
		message: 'Error 500',
		translation_key: 'error.code.500'
	}
};

export const errorCodesGroupDelete = {
	404: {
		message: 'Group not found',
		translation_key: 'error.code.group.delete.404'
	},
	406: {
		message: 'Can’t be deleted',
		translation_key: 'error.code.group.delete.406'
	},
	409: {
		message: 'Group id has child so can\'t be deleted',
		translation_key: 'error.code.group.delete.409'
	},
	422: {
		message: 'Invalid request',
		translation_key: 'error.code.422'
	},
	500: {
		message: 'Error 500',
		translation_key: 'error.code.500'
	},
};

export const errorCodesGroup = {
	404: {
		message: 'Group not found',
		translation_key: 'error.code.group.404'
	},
	406: {
		message: 'Cannot be proceed',
		translation_key: 'error.code.group.406'
	},
	409: {
		message: 'Group already exist',
		translation_key: 'error.code.group.409'
	},
	413: {
		message: 'Parent is a last',
		translation_key: 'error.code.group.413'
	},
	417: {
		message: 'Cannot set this group like parent. This group is a child of updated group',
		translation_key: 'error.code.group.417'
	},
	422: {
		message: 'Invalid request',
		translation_key: 'error.code.422'
	},
};

export const errorCodesCreateKey = {
	404: {
		message: 'Employee not found',
		translation_key: 'error.code.createKey.404'
	},
	413: {
		message: 'Account not have license for this personal test',
		translation_key: 'error.code.createKey.413'
	},
	422: {
		message: 'Invalid request',
		translation_key: 'error.code.422'
	},
	500: {
		message: 'Error 500',
		translation_key: 'error.code.500'
	},
};

export const errorCodesBindKey = {
	404: {
		message: 'Ключ не найден',
		translation_key: 'error.code.bindKey.404'
	},
};

export const errorCodesUnbindKey = {
	404: {
		message: 'Ключ не найден',
		translation_key: 'error.code.bindKey.404'
	},
	413: {
		message: 'TEST_ALREADY_ACCEPT',
		translation_key: 'error.code.bindKey.413'
	},
};

export const successCodes = {
	updateUserAccount: {
		message: 'Ваши данные успешно изменены.',
		translation_key: 'success.code.updateAccount'
	},
	changeUserPassword: {
		message: 'Ваш пароль успешно изменен.',
		translation_key: 'success.code.changePassword'
	},
	updateGroup: {
		message: 'Группа успешно сохранена',
		translation_key: 'success.code.updateGroup'
	},
	createTestEmployeeSaga: {
		message: 'Ключ успешно создан',
		translation_key: 'success.code.createTestEmployeeSaga'
	},
	updateReportGroup: {
		message: 'Групповой отчет успешно сохранен',
		translation_key: 'success.code.updateReportGroup'
	}
};

export const errorsCodesReport = { // demo error list (need endpoint for report)
	400: {
		message: 'Such name already exists',
		translation_key: 'error.code.report.delete.404'
	},
	409: {
		message: 'Test not complete',
		translation_key: 'error.code.report.not.complete.409'
	}
};

export const errorsCodesShareReport = {
	404: {
		message: 'Аккаунт не найден',
		translation_key: 'error.code.report.share.404'
	},
	500: {
		message: 'Internal Server Error',
		translation_key: 'error.code.report.share.404'
	}
};

export const errorCodesReportIndividual = {
	404: {
		message: 'Employee not found.',
		translation_key: 'error.code.reportIndividual.404'
	},
	409: {
		message: 'Test not complete.',
		translation_key: 'error.code.reportIndividual.409'
	},
	422: {
		message: 'Invalid request',
		translation_key: 'error.code.422'
	},
};

export const errorsCodesDeleteReportGroup = {
	404: {
		message: 'Group report not found',
		translation_key: 'error.code.report.group.not.found.404'
	}
};

export const errorsCodesReportGroup = {
	404: {
		message: 'Group report not found',
		translation_key: 'error.code.report.group.not.found.404'
	}
};
