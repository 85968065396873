import uk from './uk';
import ru from './ru';
import en from './en';

const messages = {
	...uk,
	...ru,
	...en,
};

export default messages;
