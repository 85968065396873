export default {
	en: {
		'account.title': 'Добро пожаловать{name}!',
		'account.to-demo': 'Также предлагаем вам пройти бессплатную demo-версию теста',
		'account.to-org-instruction-1': 'Вы можете получить  дополнительные возможности, зарегистрировав свой аккаунт как организацию.',
		'account.to-org-instruction-2': 'Для перехода в статус "организация" необходимо заполнить форму в профиле',
		'account.to-org-title': 'Зарегистрировать организацию',
		'account.to-test': 'Перейти к тестированию',
		'account.to-test-instruction': 'Для того, чтобы перейти к тестированию вам необходимо оплатить и получить ключ доступа',
		'header.count-label': 'Доступно:',
		'header.count': '{count} {count, plural, one {ключ} other {ключей}}',
		'general.demo': 'Demo',
		'general.enter': 'Вход',
		'general.to-buy': 'Перейти к оплате',
		'general.to-profile': 'Перейти в профиль',
		'general.license-buy': 'Купить ключи',
		'license.count': '{count, plural, one {ключ} few {ключи} other {ключей}}',
	}
};
