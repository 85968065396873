import queryString from 'query-string';
import { fetchJSON } from '../utils/fetchJSON';

export default (token) => {
	const parameters = {
		method: 'POST',
		headers: {
			Authorization: 'Basic'
		},
	};

	return fetchJSON(`/api/employee/add/organization/?${queryString.stringify({ token })}`, parameters);
};
