import { fromJS } from 'immutable';
import {
	REQUEST,
	SUCCESS,
	ERROR,
	GET_ALL_TEST,
	RESET_ERROR,
	CREATE_REPORT_GROUP,
	SHARE_REPORT,
	UNSHARE_REPORT,
	GET_ALL_REPORTS_GROUP,
	DELETE_REPORT_GROUP,
	UPDATE_REPORT_GROUP,
	DELETE_REPORT_INDIVIDUAL
} from '../../store/constants';

export const resetErrorAction = type => ({
	type: type + RESET_ERROR
});

export const getAllTestAction = () => ({
	type: GET_ALL_TEST
});

export const getAllReportsGroupListAction = () => ({
	type: GET_ALL_REPORTS_GROUP
});

export const createReportGroupAction = data => ({
	type: CREATE_REPORT_GROUP,
	payload: data
});

export const shareReportAction = data => ({
	type: SHARE_REPORT,
	payload: data
});
export const unShareReportAction = data => ({
	type: UNSHARE_REPORT,
	payload: data
});

export const deleteReportGroupAction = id => ({
	type: DELETE_REPORT_GROUP,
	payload: id
});

export const deleteReportIndividualAction = data => ({
	type: DELETE_REPORT_INDIVIDUAL,
	payload: data
});

export const updateReportGroupAction = data => ({
	type: UPDATE_REPORT_GROUP,
	payload: data
});

const initialState = fromJS({
	isFetching: {},
	error: {},
	reportsGroupList: null,
	allTest: null
});

const actionHandlers = {
	[CREATE_REPORT_GROUP + RESET_ERROR]: state => state
		.deleteIn(['error', 'reportGroup']),
	[CREATE_REPORT_GROUP + REQUEST]: state => state
		.setIn(['isFetching', 'reportGroup'], true)
		.deleteIn(['error', 'reportGroup']),
	[CREATE_REPORT_GROUP + SUCCESS]: state => state
		.setIn(['isFetching', 'reportGroup'], false),
	[CREATE_REPORT_GROUP + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'reportGroup'], false)
			.setIn(['error', 'reportGroup'], fromJS(payload));
	},
	[CREATE_REPORT_GROUP + RESET_ERROR]: state => state
		.deleteIn(['error', 'reportGroup']),
	[SHARE_REPORT + RESET_ERROR]: state => state
		.deleteIn(['error', 'reportGroup']),
	[SHARE_REPORT + REQUEST]: state => state
		.setIn(['isFetching', 'reportGroup'], true)
		.deleteIn(['error', 'reportGroup']),
	[SHARE_REPORT + SUCCESS]: state => state
		.setIn(['isFetching', 'reportGroup'], false),
	[SHARE_REPORT + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'reportGroup'], false)
			.setIn(['error', 'reportGroup'], fromJS(payload));
	},
	[UNSHARE_REPORT + RESET_ERROR]: state => state
		.deleteIn(['error', 'reportGroup']),
	[UNSHARE_REPORT + REQUEST]: state => state
		.setIn(['isFetching', 'reportGroup'], true)
		.deleteIn(['error', 'reportGroup']),
	[UNSHARE_REPORT + SUCCESS]: state => state
		.setIn(['isFetching', 'reportGroup'], false),
	[UNSHARE_REPORT + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'reportGroup'], false)
			.setIn(['error', 'reportGroup'], fromJS(payload));
	},
	[GET_ALL_TEST + RESET_ERROR]: state => state
		.deleteIn(['error', 'allTest']),

	[GET_ALL_TEST + REQUEST]: state => state
		.setIn(['isFetching', 'allTest'], true)
		.deleteIn(['error', 'saveReport']),
	[GET_ALL_TEST + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'allTest'], false)
			.set('allTest', fromJS(payload));
	},
	[GET_ALL_TEST + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'allTest'], false)
			.setIn(['error', 'allTest'], fromJS(payload));
	},
	[GET_ALL_TEST + RESET_ERROR]: state => state
		.deleteIn(['error', 'saveReport']),
	[GET_ALL_REPORTS_GROUP + REQUEST]: state => state
		.setIn(['isFetching', 'reportsGroupList'], true)
		.deleteIn(['error', 'reportsGroupList']),
	[GET_ALL_REPORTS_GROUP + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'reportsGroupList'], false)
			.set('reportsGroupList', fromJS(payload));
	},
	[GET_ALL_REPORTS_GROUP + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'reportsGroupList'], false)
			.setIn(['error', 'reportsGroupList'], fromJS(payload));
	},
	[GET_ALL_REPORTS_GROUP + RESET_ERROR]: state => state
		.deleteIn(['error', 'reportsGroupList']),

	[DELETE_REPORT_GROUP + REQUEST]: state => state
		.setIn(['isFetching', 'deleteReportGroup'], true)
		.deleteIn(['error', 'deleteReportGroup']),
	[DELETE_REPORT_GROUP + SUCCESS]: state => state
		.setIn(['isFetching', 'deleteReportGroup'], false),
	[DELETE_REPORT_GROUP + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'deleteReportGroup'], false)
			.setIn(['error', 'deleteReportGroup'], fromJS(payload));
	},
	[DELETE_REPORT_GROUP + RESET_ERROR]: state => state
		.deleteIn(['error', 'deleteReportGroup']),
	[DELETE_REPORT_INDIVIDUAL + REQUEST]: state => state
		.setIn(['isFetching', 'deleteReportIndividual'], true)
		.deleteIn(['error', 'deleteReportIndividual']),
	[DELETE_REPORT_INDIVIDUAL + SUCCESS]: state => state
		.setIn(['isFetching', 'deleteReportIndividual'], false),
	[DELETE_REPORT_INDIVIDUAL + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'deleteReportIndividual'], false)
			.setIn(['error', 'deleteReportIndividual'], fromJS(payload));
	},
	[DELETE_REPORT_INDIVIDUAL + RESET_ERROR]: state => state
		.deleteIn(['error', 'deleteReportIndividual']),
	[UPDATE_REPORT_GROUP + REQUEST]: state => state
		.setIn(['isFetching', 'updateReportGroup'], true)
		.deleteIn(['error', 'updateReportGroup']),
	[UPDATE_REPORT_GROUP + SUCCESS]: state => (
		state.setIn(['isFetching', 'updateReportGroup'], false)
	),
	[UPDATE_REPORT_GROUP + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'updateReportGroup'], false)
			.setIn(['error', 'updateReportGroup'], fromJS(payload));
	},
};

function reducer(state = initialState, action) {
	const handler = actionHandlers[action.type];
	return handler ? handler(state, action) : state;
}

const actionCreators = {
	resetErrorAction,
	getAllTestAction,
	createReportGroupAction,
	getAllReportsGroupListAction,
	deleteReportGroupAction,
	updateReportGroupAction,
	deleteReportIndividualAction,
	actionHandlers
};

export { actionCreators };

export default reducer;
