import Cookie from 'js-cookie';
import { AUTH_TOKEN } from '../store/constants';

export const getToken = () => ({
	[AUTH_TOKEN]: Cookie.get(AUTH_TOKEN)
});

export const isLoggedIn = () => !!Cookie.get(AUTH_TOKEN);

export const setToken = (token) => {
	Cookie.set(AUTH_TOKEN, token, { expires: 30 });
};

export const removeToken = () => {
	Cookie.remove(AUTH_TOKEN);
};
