import { put, call } from 'redux-saga/effects';
import {
	getLicenseCountService,
	getLicenseHistoryService,
	getLicenseUsedService,
} from '../services/licenseService';

import * as types from '../store/constants';

export function* getLicenseCountSaga() {
	yield put({ type: `${types.GET_LICENSE_COUNT}${types.REQUEST}` });
	try {
		const response = yield call(getLicenseCountService);
		yield put({ type: `${types.GET_LICENSE_COUNT}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.GET_LICENSE_COUNT}${types.ERROR}`, payload: error });
	}
}

export function* getLicenseHistorySaga() {
	yield put({ type: `${types.GET_LICENSE_HISTORY}${types.REQUEST}` });
	try {
		const response = yield call(getLicenseHistoryService);
		yield put({ type: `${types.GET_LICENSE_HISTORY}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.GET_LICENSE_HISTORY}${types.ERROR}`, payload: error });
	}
}

export function* getLicenseUsedSaga() {
	yield put({ type: `${types.GET_LICENSE_USED}${types.REQUEST}` });
	try {
		const response = yield call(getLicenseUsedService);
		yield put({ type: `${types.GET_LICENSE_USED}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.GET_LICENSE_USED}${types.ERROR}`, payload: error });
	}
}
