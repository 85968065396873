import React, { lazy, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	func, instanceOf
} from 'prop-types';
import { Iterable } from 'immutable';
import ModalSuccess from '../../components/ModalSuccess/ModalSuccess';
import EmployeeTokenModal from '../../components/EmployeeTokenModal';
import { getLiteralsAction } from '../../reducers/modules/auth';

const Auth = lazy(() => import('./layout/Auth'));

const CoreLayout = ({ _getLiterals, _literals }) => {
	useEffect(() => {
		if (!_literals || !Object.keys(_literals.toJS()).length) {
			_getLiterals();
		}
	});

	return (
		<Suspense fallback={<div>Loading...</div>}>
			<EmployeeTokenModal />
			<Auth />
			<ModalSuccess />
		</Suspense>
	);
};

CoreLayout.propTypes = {
	_getLiterals: func.isRequired,
	_literals: instanceOf(Iterable).isRequired,
};

// export default CoreLayout;
export default connect(state => ({
	_literals: state.auth.get('literals'),
}), {
	_getLiterals: getLiteralsAction
})(CoreLayout);
