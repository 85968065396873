export default {
	ru: {
		'account.label.activity': '*Вид деятельности',
		'account.label.employee': '*Кол-во сотрудников:',
		'account.label.name': '*Название организации',
		'account.organization.title': 'Для перехода в статус "Организация", пожалуйста, заполните форму.',
		'account.title': 'Добро пожаловать{name}!',
		'account.to-demo': 'Также предлагаем вам пройти бессплатную demo-версию теста',
		'account.to-org-instruction-1': 'Вы можете получить  дополнительные возможности, зарегистрировав свой аккаунт как организацию.',
		'account.to-org-instruction-2': 'Для перехода в статус "организация" необходимо заполнить форму в профиле',
		'account.to-org-title': 'Зарегистрировать организацию',
		'account.to-test': 'Перейти к тестированию',
		'account.to-test-instruction': 'Для того, чтобы перейти к тестированию вам необходимо оплатить и получить ключ доступа',
		'account.upgrade-instruction': 'Вы зарегистрированы как "Частное лицо". Для получения расширенных возможностей перейдите в статус "Организация", сделав апгрейд профиля',
		'header.count-label': 'Доступно:',
		'header.count': '{count} {count, plural, one {ключ} other {ключей}}',
		'general.demo': 'Демо-тест',
		'general.enter': 'Вход',
		'general.register': 'Регистрация',
		'general.save': 'Сохранить',
		'general.to-buy': 'Перейти к оплате',
		'general.to-profile': 'Перейти в профиль',
		'general.license-buy': 'Купить ключи',
		'license.count': '{count, plural, one {ключ} few {ключи} other {ключей}}',
		'register.success': 'Для подтверждения регистрации на вашу почту было отправлено письмо с дальнейшей инструкцией',
	}
};
