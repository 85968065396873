import { fetchJSON } from '../utils/fetchJSON';

// eslint-disable-next-line import/prefer-default-export
export const getLiteralsService = async (request = {}) => {
	const literalType = request.payload ? request.payload.literalType : undefined;
	const parameters = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	};

	return fetchJSON(`/api/literals${literalType ? `?literalType=${literalType}` : ''}`, parameters);
};
