import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import auth from '../reducers/modules/auth';
import modal from '../reducers/modules/modal';
import user from '../reducers/modules/user';
import structure from '../reducers/modules/structure';
import license from '../reducers/modules/license';
import report from '../reducers/modules/report';
import payment from '../reducers/modules/payment';
import skills from '../reducers/modules/skills';

export default combineReducers({
	form: formReducer,
	auth,
	modal,
	user,
	structure,
	license,
	report,
	payment,
	skills
});
