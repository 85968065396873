import { Component } from 'react';
import { withRouter } from 'react-router';
import {
	arrayOf,
	oneOfType,
	element,
	node,
	shape,
	string
} from 'prop-types';

class ScrollRestoration extends Component {
	componentDidUpdate(prevProps) {
		const { location: { pathname: prevPathname } } = prevProps;
		const { location: { pathname } } = this.props;
		if (pathname !== prevPathname) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		const { children } = this.props;
		return (children);
	}
}

ScrollRestoration.propTypes = {
	location: shape({
		pathname: string.isRequired,
	}).isRequired,
	children: oneOfType([element, arrayOf(element), node]).isRequired
};

export default withRouter(ScrollRestoration);
