import { fromJS } from 'immutable';
import {
	MODAL_CLOSE,
	MODAL_OPEN,
	MODAL_SWITCH,
	PUBLIC_MODAL_AUTH_LOGIN,
	MODAL_STEP_ONE
} from '../../store/constants';

export const closeModal = modalType => ({
	type: MODAL_CLOSE,
	payload: { modalType }
});

export const openModal = (modalType, modalData) => ({
	type: MODAL_OPEN,
	payload: {
		modalType,
		modalData
	}
});

export const switchModal = (
	oldModalName, newModalName, oldModalData, newModalData
) => ({
	type: MODAL_SWITCH,
	payload: {
		oldModalName,
		oldModalData,
		newModalName,
		newModalData
	}
});

const initialState = fromJS({
	modalData: {
		modalAuth: {
			isOpen: false,
			data: PUBLIC_MODAL_AUTH_LOGIN
		},
		modalBuy: {
			isOpen: false,
		},
		modalPasswordReset: {
			isOpen: true,
			data: MODAL_STEP_ONE
		},
		modalPasswordChange: {
			isOpen: false,
		},
		modalShare: {
			isOpen: false,
		},
		modalSearchSkills: {
			isOpen: false,
		},
		modalShareSettings: {
			isOpen: false,
		},
		modalRemoveFinally: {
			isOpen: false,
		},
		modalDeleteReferenceProfile: {
			isOpen: false,
		},
		modalReferenceProfile: {
			isOpen: false,
		},
		modalCreateGroup: {
			isOpen: false,
			data: {
				step: MODAL_STEP_ONE
			}
		},
		modalCreateEmployer: {
			isOpen: false,
			data: MODAL_STEP_ONE
		},
		modalSuccess: {
			isOpen: false
		},
		modalDelete: {
			isOpen: false
		},
		modalRestore: {
			isOpen: false
		},
		modalGroupReport: {
			isOpen: false,
			data: {
				step: MODAL_STEP_ONE,
				checkedEmployees: null
			}
		},
		modalAddEmployeeToGroup: {
			isOpen: false,
			data: {
				step: MODAL_STEP_ONE
			}
		},
		modalGroupDelete: {
			isOpen: false,
			data: {
				step: MODAL_STEP_ONE,
				id: null
			}
		},
		modalCreateTestEmployee: {
			isOpen: false,
			data: {
				step: MODAL_STEP_ONE,
				id: null
			}
		},
		modalBindKey: {
			isOpen: false,
			data: {
				step: MODAL_STEP_ONE,
				id: null
			}
		},
		modalUnbindKey: {
			isOpen: false,
			data: {
				step: MODAL_STEP_ONE,
				id: null,
				keys: []
			}
		},
		modalDeleteReportGroup: {
			isOpen: false,
			data: {
				step: MODAL_STEP_ONE,
				id: null
			}
		},
		modalDeleteIndividualReport: {
			isOpen: false,
			data: {
				step: MODAL_STEP_ONE,
				employeeId: null,
				testInstanceId: null
			}
		}
	},
	previousModal: {
		type: null,
		data: null
	}
});

const actionHandlers = {
	[MODAL_CLOSE]: (state, action) => {
		const { payload: { modalType } } = action;

		const closedModal = state.setIn(['modalData', modalType, 'isOpen'], false);
		const hasModalData = state.hasIn(['modalData', modalType, 'data']);

		return hasModalData
			? closedModal.setIn(['modalData', modalType, 'data'], null)
			: closedModal;
	},
	[MODAL_OPEN]: (state, action) => {
		const { payload: { modalType, modalData } } = action;
		const openedModal = state.setIn(['modalData', modalType, 'isOpen'], true);

		return modalData
			? openedModal.setIn(['modalData', modalType, 'data'], modalData)
			: openedModal;
	},
	[MODAL_SWITCH]: (state, action) => {
		const {
			payload: {
				oldModalName, oldModalData, newModalName, newModalData
			}
		} = action;

		let switchedModal = state
			.setIn(['previousModal', 'type'], oldModalName)
			.setIn(['modalData', oldModalName, 'isOpen'], false)
			.setIn(['modalData', newModalName, 'isOpen'], true);

		if (oldModalData) switchedModal = switchedModal.setIn(['previousModal', 'data'], oldModalData);
		if (state.hasIn(['modalData', oldModalName, 'data'])) {
			switchedModal = switchedModal.setIn(['modalData', oldModalName, 'data'], null);
		}
		if (newModalData) switchedModal = switchedModal.setIn(['modalData', newModalName, 'data'], newModalData);

		return switchedModal;
	},
};

function reducer(state = initialState, action) {
	const handler = actionHandlers[action.type];
	return handler ? handler(state, action) : state;
}

const actionCreators = {
	closeModal,
	openModal,
	switchModal
};

export { actionCreators };

export default reducer;
