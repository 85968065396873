import { put, call } from 'redux-saga/effects';
import {
	completePaypalOrderService,
	cancelPaypalOrderService,
	createPaypalOrderService,
	completePaypalOrderWithoutAuthService,
	cancelPaypalOrderWithoutAuthService,
	createPaypalOrderWithoutAuthService,

	completeStripeOrderService,
	cancelStripeOrderService,
	createStripeOrderService,
	getStripeConfigService,
	completeStripeOrderWithoutAuthService,
	cancelStripeOrderWithoutAuthService,
	createStripeOrderWithoutAuthService,

	checkPromoCodeService,
} from '../services/paymentService';
import {
	getAccountLicenseCountSaga,
	getKeyPersonSaga
} from './userSaga';
import localStorage from '../utils/localStorage';
import getStripeConfig from '../ajax/getStripeConfig';
import * as types from '../store/constants';

async function getStripeConfigLocal(data) {
	const response = await getStripeConfig();
	const stripe = window.Stripe(response.publickToken);
	localStorage().setItem('stripeSessionId', data.stripeSessionId);
	stripe.redirectToCheckout({
		sessionId: data.stripeSessionId
	});
}

// <---- saga with auth
export function* completePaypalOrderSaga(payload) {
	yield put({ type: `${types.COMPLETE_PAYPAL_PAYMENT}${types.REQUEST}` });
	try {
		const response = yield call(completePaypalOrderService, payload);
		yield put({ type: `${types.COMPLETE_PAYPAL_PAYMENT}${types.SUCCESS}`, payload: response });
		yield getKeyPersonSaga();
		yield getAccountLicenseCountSaga();
	} catch (error) {
		yield put({ type: `${types.COMPLETE_PAYPAL_PAYMENT}${types.ERROR}`, payload: error });
	}
}

export function* cancelPaypalOrderSaga(payload) {
	yield put({ type: `${types.CANCEL_PAYPAL_PAYMENT}${types.REQUEST}` });
	try {
		const response = yield call(cancelPaypalOrderService, payload);
		yield put({ type: `${types.CANCEL_PAYPAL_PAYMENT}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.CANCEL_PAYPAL_PAYMENT}${types.ERROR}`, payload: error });
	}
}

export function* createPaypalOrderSaga(payload) {
	yield put({ type: `${types.CREATE_PAYPAL_PAYMENT}${types.REQUEST}` });
	try {
		const response = yield call(createPaypalOrderService, payload);
		yield window.location.assign(response.pay_pal_url);
		yield put({
			type: types.MODAL_CLOSE,
			payload: {
				modalType: types.ACCOUNT_MODAL_BUY
			}
		});
		yield put({ type: `${types.CREATE_PAYPAL_PAYMENT}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.CREATE_PAYPAL_PAYMENT}${types.ERROR}`, payload: error });
	}
}

// ----> saga with auth

// <---- saga without auth
export function* completePaypalOrderWithoutAuthSaga(payload) {
	yield put({ type: `${types.COMPLETE_PAYPAL_PAYMENT}${types.REQUEST}` });
	try {
		const response = yield call(completePaypalOrderWithoutAuthService, payload);
		yield put({ type: `${types.COMPLETE_PAYPAL_PAYMENT}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.COMPLETE_PAYPAL_PAYMENT}${types.ERROR}`, payload: error });
	}
}

export function* cancelPaypalOrderWithoutAuthSaga(payload) {
	yield put({ type: `${types.CANCEL_PAYPAL_PAYMENT}${types.REQUEST}` });
	try {
		const response = yield call(cancelPaypalOrderWithoutAuthService, payload);
		yield put({ type: `${types.CANCEL_PAYPAL_PAYMENT}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.CANCEL_PAYPAL_PAYMENT}${types.ERROR}`, payload: error });
	}
}

export function* createPaypalOrderWithoutAuthSaga(payload) {
	yield put({ type: `${types.CREATE_PAYPAL_PAYMENT}${types.REQUEST}` });
	try {
		const response = yield call(createPaypalOrderWithoutAuthService, payload);
		yield window.location.assign(response.pay_pal_url);
		yield put({ type: `${types.CREATE_PAYPAL_PAYMENT}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.CREATE_PAYPAL_PAYMENT}${types.ERROR}`, payload: error });
	}
}

// ----> saga without auth

export function* checkPromoCodeSaga(payload) {
	yield put({ type: `${types.PROMO_CODE_PAYMENT}${types.REQUEST}` });
	try {
		const response = yield call(checkPromoCodeService, payload);
		yield put({ type: `${types.PROMO_CODE_PAYMENT}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.PROMO_CODE_PAYMENT}${types.ERROR}`, payload: error });
	}
}

// <---- Stripe service with auth

export function* completeStripeOrderSaga(payload) {
	yield put({ type: `${types.COMPLETE_STRIPE_PAYMENT}${types.REQUEST}` });
	try {
		const response = yield call(completeStripeOrderService, payload);
		yield put({ type: `${types.COMPLETE_STRIPE_PAYMENT}${types.SUCCESS}`, payload: response });
		yield getKeyPersonSaga();
		yield getAccountLicenseCountSaga();
	} catch (error) {
		yield put({ type: `${types.COMPLETE_STRIPE_PAYMENT}${types.ERROR}`, payload: error });
	}
}

export function* cancelStripeOrderSaga(payload) {
	yield put({ type: `${types.CANCEL_STRIPE_PAYMENT}${types.REQUEST}` });
	try {
		const response = yield call(cancelStripeOrderService, payload);
		yield put({ type: `${types.CANCEL_STRIPE_PAYMENT}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.CANCEL_STRIPE_PAYMENT}${types.ERROR}`, payload: error });
	}
}

export function* createStripeOrderSaga(payload) {
	yield put({ type: `${types.CREATE_STRIPE_PAYMENT}${types.REQUEST}` });
	try {
		const response = yield call(createStripeOrderService, payload);
		yield put({ type: `${types.CREATE_STRIPE_PAYMENT}${types.SUCCESS}`, payload: response });
		yield getStripeConfigLocal(response);
		yield put({
			type: types.MODAL_CLOSE,
			payload: {
				modalType: types.ACCOUNT_MODAL_BUY
			}
		});
	} catch (error) {
		yield put({ type: `${types.CREATE_STRIPE_PAYMENT}${types.ERROR}`, payload: error });
	}
}

export function* getStripeConfigSaga(payload) {
	yield put({ type: `${types.GET_STRIPE_CONFIG}${types.REQUEST}` });
	try {
		const response = yield call(getStripeConfigService, payload);
		yield put({ type: `${types.GET_STRIPE_CONFIG}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.GET_STRIPE_CONFIG}${types.ERROR}`, payload: error });
	}
}

// ----> Stripe service with auth

// <---- Stripe service without auth

export function* completeStripeOrderWithoutAuthSaga(payload) {
	yield put({ type: `${types.COMPLETE_STRIPE_PAYMENT_WITHOUT_AUTH}${types.REQUEST}` });
	try {
		const response = yield call(completeStripeOrderWithoutAuthService, payload);
		yield put({ type: `${types.COMPLETE_STRIPE_PAYMENT}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.COMPLETE_STRIPE_PAYMENT}${types.ERROR}`, payload: error });
	}
}

export function* cancelStripeOrderWithoutAuthSaga(payload) {
	yield put({ type: `${types.CANCEL_STRIPE_PAYMENT}${types.REQUEST}` });
	try {
		const response = yield call(cancelStripeOrderWithoutAuthService, payload);
		yield put({ type: `${types.CANCEL_STRIPE_PAYMENT}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.CANCEL_STRIPE_PAYMENT}${types.ERROR}`, payload: error });
	}
}

export function* createStripeOrderWithoutAuthSaga(payload) {
	yield put({ type: `${types.CREATE_STRIPE_PAYMENT}${types.REQUEST}` });
	try {
		const response = yield call(createStripeOrderWithoutAuthService, payload);
		yield put({ type: `${types.CREATE_STRIPE_PAYMENT}${types.SUCCESS}`, payload: response });
		yield getStripeConfigLocal(response);
		yield put({
			type: types.MODAL_CLOSE,
			payload: {
				modalType: types.ACCOUNT_MODAL_BUY
			}
		});
	} catch (error) {
		yield put({ type: `${types.CREATE_STRIPE_PAYMENT}${types.ERROR}`, payload: error });
	}
}

// ----> Stripe service without auth
