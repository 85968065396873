import { fork } from 'redux-saga/effects';
import {
	watchUserAuthentication,
	watchUserAccount,
	watchStructure,
	watchLicense,
	watchReport,
	watchPayment,
	watchSkills,
} from './watchers';

export default function* startForman() {
	yield fork(watchUserAuthentication);
	yield fork(watchUserAccount);
	yield fork(watchStructure);
	yield fork(watchLicense);
	yield fork(watchReport);
	yield fork(watchPayment);
	yield fork(watchSkills);
}
