import React from 'react';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import modalType from '../../types/modalType';
import { closeModal } from '../../reducers/modules/modal';
import GeneralModal from '../GeneralModal';
import info from '../../static/i/info.svg';
import './ModalSuccess.sass';

import { MODAL_SUCCESS } from '../../store/constants';

const ModalSuccess = ({ _modal, _closeModal }) => {
	const handleClose = () => {
		_closeModal(MODAL_SUCCESS);
	};

	const message = _modal.getIn(['data', 'message']);
	return (
		<GeneralModal
			isModalOpen={_modal.get('isOpen')}
			onCloseModal={handleClose}
			modalContentClassName='general-modal__body_modal-success'
		>
			<div className='modal-success'>
				<img src={info} alt='' />
				<div className='modal-success__text'>
					{ message || 'Данные успешно обновлены' }
				</div>
				<div className='modal-success__button'>
					<button
						type='button'
						className='btn btn-default btn-w100'
						onClick={handleClose}
					>
						Закрыть
					</button>
				</div>
			</div>
		</GeneralModal>
	);
};

ModalSuccess.propTypes = {
	_modal: modalType.isRequired,
	_closeModal: func.isRequired
};

export default connect(state => ({
	_modal: state.modal.getIn(['modalData', MODAL_SUCCESS])
}), {
	_closeModal: closeModal
})(ModalSuccess);
