import { XLSX, XLS } from '../store/constants';

export default (response) => {
	const url = window.URL.createObjectURL(response, { type: response.type });
	let expansionFile = '';
	switch (response.type) {
		case XLSX.type:
			expansionFile = XLSX.expansion;
			break;
		case XLS.type:
			expansionFile = XLS.expansion;
			break;
		default:
			break;
	}
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', `structure.${expansionFile}`);
	link.click();
	window.URL.revokeObjectURL(url);
};
