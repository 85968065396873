import { put, call } from 'redux-saga/effects';
import * as types from '../store/constants';
import {
	successCodes
} from '../static/errors';
import history from '../store/history';
import {
	createReportGroupService,
	shareReportService,
	unShareReportService,
	getAllTestService,
	getAllReportsGroupService,
	deleteReportGroupService,
	updateReportGroupService,
	deleteReportIndividualService
} from '../services/reportService';

import {
	getAllEmployee
} from './structureSaga';

export function* createReportGroup(data) {
	const {
		payload: {
			groupReportName
		}
	} = data;

	yield put({ type: `${types.CREATE_REPORT_GROUP}${types.REQUEST}` });
	try {
		const response = yield call(createReportGroupService, data.payload);
		yield put({ type: `${types.CREATE_REPORT_GROUP}${types.SUCCESS}` });
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.MODAL_GROUP_REPORT,
				modalData: { step: types.MODAL_STEP_TWO, groupReportName, id: response.id }
			}
		});
	} catch (error) {
		yield put({ type: `${types.CREATE_REPORT_GROUP}${types.ERROR}`, payload: error });
	}
}

export function* shareReport(data) {
	const {
		payload: {
			groupReportName
		}
	} = data;

	yield put({ type: `${types.SHARE_REPORT}${types.REQUEST}` });
	try {
		const response = yield call(shareReportService, data.payload);
		yield put({ type: `${types.SHARE_REPORT}${types.SUCCESS}` });
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: data.payload.isSettingsPopup ? types.MODAL_SHARE_SETTINGS : types.MODAL_SHARE,
				modalData: { step: types.MODAL_STEP_TWO, groupReportName, id: response.id }
			}
		});
		yield call(getAllEmployee);
	} catch (error) {
		yield put({ type: `${types.SHARE_REPORT}${types.ERROR}`, payload: error });
	}
}

export function* unShareReport(data) {
	const {
		payload: {
			groupReportName
		}
	} = data;

	yield put({ type: `${types.UNSHARE_REPORT}${types.REQUEST}` });
	try {
		const response = yield call(unShareReportService, data.payload);
		yield put({ type: `${types.UNSHARE_REPORT}${types.SUCCESS}` });

		if (data.payload.isLastRequest) {
			yield call(getAllEmployee);

			yield put({
				type: types.MODAL_OPEN,
				payload: {
					modalType: types.MODAL_SHARE_SETTINGS,
					modalData: { step: types.MODAL_STEP_TWO, groupReportName, id: response.id }
				}
			});
		}
	} catch (error) {
		yield put({ type: `${types.UNSHARE_REPORT}${types.ERROR}`, payload: error });
	}
}

export function* getAllTest() {
	yield put({ type: `${types.GET_ALL_TEST}${types.REQUEST}` });
	try {
		const response = yield call(getAllTestService);
		yield put({ type: `${types.GET_ALL_TEST}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.GET_ALL_TEST}${types.ERROR}`, payload: error });
	}
}

export function* getAllReportsGroup() {
	yield put({ type: `${types.GET_ALL_REPORTS_GROUP}${types.REQUEST}` });
	try {
		const response = yield call(getAllReportsGroupService);
		yield put({ type: `${types.GET_ALL_REPORTS_GROUP}${types.SUCCESS}`, payload: response });
	} catch (error) {
		yield put({ type: `${types.GET_ALL_REPORTS_GROUP}${types.ERROR}`, payload: error });
	}
}

export function* deleteReportGroup(data) {
	const { payload: { id } } = data;
	yield put({ type: `${types.DELETE_REPORT_GROUP}${types.REQUEST}` });
	try {
		const response = yield call(deleteReportGroupService, id);
		yield put({ type: `${types.DELETE_REPORT_GROUP}${types.SUCCESS}`, payload: response });
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.MODAL_DELETE_REPORT_GROUP,
				modalData: { step: types.MODAL_STEP_TWO }
			}
		});
		yield call(history.push, '/account/reports');
		yield call(getAllReportsGroup);
	} catch (error) {
		yield put({ type: `${types.DELETE_REPORT_GROUP}${types.ERROR}`, payload: error });
	}
}

export function* deleteReportIndividual(data) {
	const { payload: { employeeId, testInstanceId } } = data;
	yield put({ type: `${types.DELETE_REPORT_INDIVIDUAL}${types.REQUEST}` });
	try {
		const response = yield call(deleteReportIndividualService, { employeeId, testInstanceId });
		yield put({ type: `${types.DELETE_REPORT_INDIVIDUAL}${types.SUCCESS}`, payload: response });
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.MODAL_DELETE_INDIVIDUAL_REPORT,
				modalData: { step: types.MODAL_STEP_TWO }
			}
		});
		yield call(getAllEmployee);
	} catch (error) {
		yield put({ type: `${types.DELETE_REPORT_INDIVIDUAL}${types.ERROR}`, payload: error });
	}
}

export function* updateReportGroup(data) {
	const { payload } = data;
	yield put({ type: `${types.UPDATE_REPORT_GROUP}${types.REQUEST}` });
	try {
		const response = yield call(updateReportGroupService, payload);
		yield put({ type: `${types.UPDATE_REPORT_GROUP}${types.SUCCESS}`, payload: response });
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.MODAL_SUCCESS,
				modalData: { message: successCodes.updateReportGroup.message }
			}
		});
	} catch (error) {
		yield put({ type: `${types.UPDATE_REPORT_GROUP}${types.ERROR}`, payload: error });
	}
}
