import { fromJS } from 'immutable';
import {
	REQUEST,
	SUCCESS,
	ERROR,
	RESET_ERROR,
	GET_ALL_EMPLOYEE_SKILLS,
	GET_SKILLS_FILTER,
	FIND_SKILLS_FILTER,
	FIND_ID_SKILLS_FILTER,
	CREATE_SKILLS_FILTER,
	UPDATE_SKILLS_FILTER,
	DELETE_SKILLS_FILTER,
	GET_ALL_SKILLS,
} from '../../store/constants';

const resetError = type => ({
	type: type + RESET_ERROR
});

const getAllEmployeeAction = data => ({
	type: GET_ALL_EMPLOYEE_SKILLS,
	payload: data,
});

const getSkillsFilterAction = () => ({
	type: GET_SKILLS_FILTER,
});

// TODO: запрос на создание фильтра присутсвует в folder /ajax, убрать обработку тут
const createSkillsFilterAction = data => ({
	type: CREATE_SKILLS_FILTER,
	payload: data,
});

const updateSkillsFilterAction = id => ({
	type: UPDATE_SKILLS_FILTER,
	payload: id
});

const deleteSkillsFilterAction = id => ({
	type: DELETE_SKILLS_FILTER,
	payload: id
});

const getAllSkillsAction = () => ({
	type: GET_ALL_SKILLS,
});

const findSkillsFilterAction = (data, params) => ({
	type: FIND_SKILLS_FILTER,
	payload: { data, params },
});

const findIdSkillsFilterAction = id => ({
	type: FIND_ID_SKILLS_FILTER,
	payload: id,
});

const initialState = fromJS({
	isFetching: {},
	error: {},
	employees: {
		content: []
	},
	templates: [],
	skills: [],
	findSkills: []
});

const actionHandlers = {
	[GET_ALL_EMPLOYEE_SKILLS + REQUEST]: state => state
		.setIn(['isFetching', 'employees'], true)
		.deleteIn(['error', 'employees']),
	[GET_ALL_EMPLOYEE_SKILLS + SUCCESS]: (state, action) => state
		.setIn(['isFetching', 'employees'], false)
		.set('employees', fromJS(action.payload)),
	[GET_ALL_EMPLOYEE_SKILLS + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'employees'], false)
			.setIn(['error', 'employees'], fromJS(payload));
	},

	[GET_SKILLS_FILTER + REQUEST]: state => state
		.setIn(['isFetching', 'templates'], true)
		.deleteIn(['error', 'templates']),
	[GET_SKILLS_FILTER + SUCCESS]: (state, action) => state
		.setIn(['isFetching', 'templates'], false)
		.set('templates', fromJS(action.payload)),
	[GET_SKILLS_FILTER + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'templates'], false)
			.setIn(['error', 'templates'], fromJS(payload));
	},

	[GET_ALL_SKILLS + REQUEST]: state => state
		.setIn(['isFetching', 'skills'], true)
		.deleteIn(['error', 'skills']),
	[GET_ALL_SKILLS + SUCCESS]: (state, action) => state
		.setIn(['isFetching', 'skills'], false)
		.set('skills', fromJS(action.payload)),
	[GET_ALL_SKILLS + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'skills'], false)
			.setIn(['error', 'skills'], fromJS(payload));
	},

	[FIND_SKILLS_FILTER + REQUEST]: state => state
		.setIn(['isFetching', 'employees'], true)
		.deleteIn(['error', 'employees']),
	[FIND_SKILLS_FILTER + SUCCESS]: (state, action) => state
		.setIn(['isFetching', 'employees'], false)
		.set('employees', fromJS(action.payload)),
	[FIND_SKILLS_FILTER + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'employees'], false)
			.setIn(['error', 'employees'], fromJS(payload));
	},

	[FIND_ID_SKILLS_FILTER + REQUEST]: state => state
		.setIn(['isFetching', 'employees'], true)
		.deleteIn(['error', 'employees']),
	[FIND_ID_SKILLS_FILTER + SUCCESS]: (state, action) => state
		.setIn(['isFetching', 'employees'], false)
		.set('employees', fromJS(action.payload)),
	[FIND_ID_SKILLS_FILTER + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'employees'], false)
			.setIn(['error', 'employees'], fromJS(payload));
	},
};

function reducer(state = initialState, action) {
	const handler = actionHandlers[action.type];
	return handler ? handler(state, action) : state;
}

export {
	resetError,
	getAllEmployeeAction,
	getSkillsFilterAction,
	findSkillsFilterAction,
	findIdSkillsFilterAction,
	createSkillsFilterAction,
	updateSkillsFilterAction,
	deleteSkillsFilterAction,
	getAllSkillsAction,
	actionHandlers,
};

export default reducer;
