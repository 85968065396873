import React, { Component } from 'react';
import {
	arrayOf,
	bool,
	func,
	node,
	oneOfType,
	string
} from 'prop-types';
import ReactModal from 'react-modal';
import cn from 'classnames';
import './GeneralModal.sass';

class GeneralModal extends Component {
	render() {
		const {
			children,
			isModalOpen,
			modalContentClassName,
			onCloseModal,
			overlayClassName,
			showButtonClose,
		} = this.props;

		const rootElement = document.getElementById('root');

		return (
			<ReactModal
				appElement={rootElement}
				className={cn('general-modal__body', {
					[modalContentClassName]: !!modalContentClassName
				})}
				isOpen={isModalOpen}
				onRequestClose={onCloseModal}
				shouldFocusAfterRender={false}
				overlayClassName={cn('general-modal__overlay', {
					[overlayClassName]: !!overlayClassName,
				})}
			>
				{children}
				{showButtonClose && (
					<button
						type='button'
						onClick={onCloseModal}
						className='general-modal__close icon-cross btn btn-reset'
					/>
				)}
			</ReactModal>
		);
	}
}

GeneralModal.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired,
	isModalOpen: bool.isRequired,
	modalContentClassName: string,
	onCloseModal: func.isRequired,
	overlayClassName: string,
	showButtonClose: bool,
};

GeneralModal.defaultProps = {
	modalContentClassName: null,
	overlayClassName: null,
	showButtonClose: true,
};

export default GeneralModal;
