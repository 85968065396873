import React, { useEffect, useState } from 'react';
import {
	shape, string
} from 'prop-types';
import queryString from 'query-string';
import { withRouter, matchPath } from 'react-router';
import GeneralModal from '../GeneralModal';
import SuccessInfo from '../SuccessInfo';
import checkTokenService from '../../ajax/checkToken';
import { isLoggedIn } from '../../utils/cookieToken';

const EmployeeTokenModal = ({ location }) => {
	const [employTokenApproved, setEmployTokenApproved] = useState(false);
	const { pathname } = location;

	function checkToken() {
		const { search } = location;
		const query = queryString.parse(search);

		if (query.token) {
			checkTokenService(query.token)
				.then(() => {
					setEmployTokenApproved(true);
				});
		}
	}

	useEffect(() => {
		if (isLoggedIn()) {
			checkToken();
		} else {
			const isLoginPage = matchPath(pathname, {
				path: '/',
				exact: true
			});

			if (isLoginPage) {
				checkToken();
			}
		}
	}, [pathname]);

	const handleClose = () => {
		setEmployTokenApproved(false);
	};

	return (
		<GeneralModal
			isModalOpen={employTokenApproved}
			onCloseModal={handleClose}
			modalContentClassName='general-modal__body_add-employer-modal'
		>
			<SuccessInfo
				text='Вы успешно добавлены в компанию'
				handleClose={handleClose}
			/>
		</GeneralModal>
	);
};

EmployeeTokenModal.propTypes = {
	location: shape({
		search: string.isRequired,
		pathname: string.isRequired
	}).isRequired,
};

export default withRouter(EmployeeTokenModal);
