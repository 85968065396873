import { fetchJSON, fetchFile } from '../utils/fetchJSON';
import { getToken } from '../utils/cookieToken';

export const getAllGroupService = () => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON('/api/group', parameters);
};

export const createGroupService = (request) => {
	const parameters = {
		method: 'POST',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(request.payload)
	};

	return fetchJSON('/api/group', parameters);
};

export const deleteGroupService = (request) => {
	const parameters = {
		method: 'DELETE',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON(`/api/group/${request.payload.id}`, parameters);
};

export const updateGroupService = (request) => {
	const parameters = {
		method: 'PUT',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(request.payload.body)
	};

	return fetchJSON(`/api/group/${request.payload.id}`, parameters);
};

export const addEmployeesToGroupService = (request) => {
	const parameters = {
		method: 'PUT',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(request.payload.body)
	};

	return fetchJSON(`/api/group/${request.payload.id}/add/employee`, parameters);
};

export const getAllEmployeeService = (params = {}) => {
	const {
		page,
		size,
		testState,
		testId,
		onlyShared,
		sort,
		order
	} = params;
	let { search = '' } = params;

	search = search.trim();

	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON(`/api/employee?
		page=${page || 1}&
		size=${size || 15}
		${sort ? `&sort=${sort}` : ''}
		${order ? `&order=${order}` : ''}
		${testState ? `&testState=${testState}` : ''}
		${testId ? `&testId=${testId}` : ''}
		${search ? encodeURI(`&search=email:%:%${search}%|lastName:%:%${search}%|firstName:%:%${search}%`) : ''}
		${onlyShared ? `&onlyShared=${onlyShared || false}` : ''}`, parameters);
};
// &search=${encodeURI(`email::${search},firstName:in:[${search}|${search}]`)}

// export const getAllEmployeeService = (data) => {
// 	console.log(data);
// 	const parameters = {
// 		method: 'GET',
// 		headers: {
// 			...getToken(),
// 			'Content-Type': 'application/json'
// 		},
// 	};
//
// 	return fetchJSON('/api/employee', parameters);
// };

export const getAllRemovedEmployeeService = () => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON('/api/employee/remove', parameters);
};

export const getAllReferenceProfilesService = () => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return Promise.all([
		fetchJSON('/api/client/reference?showTemplate=true', parameters),
		fetchJSON('/api/client/reference', parameters)
	]);
};

export const createEmployerService = (request) => {
	const parameters = {
		method: 'POST',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(request.payload)
	};

	return fetchJSON('/api/employee', parameters);
};

export const createReferenceProfileService = (request) => {
	const parameters = {
		method: 'POST',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(request.payload)
	};

	return fetchJSON('/api/client/reference', parameters);
};


export const deleteEmployerService = (request) => {
	const parameters = {
		method: 'DELETE',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		}
	};

	return fetchJSON(`/api/employee/${request.payload}`, parameters);
};


export const removeEmployeesFinallyService = (request) => {
	const parameters = {
		method: 'PUT',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(request.payload.removedEmployeeIds)
	};

	return fetchJSON('/api/employee/trash/remove', parameters);
};


export const deleteReferenceProfileService = (request) => {
	const parameters = {
		method: 'DELETE',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		}
	};

	return fetchJSON(`/api/client/reference/${request.payload.id}`, parameters);
};

export const restoreEmployerService = (request) => {
	const parameters = {
		method: 'PUT',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		}
	};

	return fetchJSON(`/api/employee/${request.payload}/restore`, parameters);
};

export const updateEmployerService = (request) => {
	const parameters = {
		method: 'PUT',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(request.payload.data)
	};

	return fetchJSON(`/api/employee/${request.payload.id}`, parameters);
};

export const downloadStructureService = (request) => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'blob'
		},
	};

	return fetchFile(`/api/organization/export?type=${request.payload}`, parameters);
};

export const createTestEmployeeService = (request) => {
	const parameters = {
		method: 'POST',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ personal_test_id: request.payload.testId })
	};

	return fetchJSON(`/api/employee/${request.payload.id}/personal-test`, parameters);
};

export const bindKeyService = (request) => {
	const parameters = {
		method: 'POST',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ key: request.payload.key })
	};

	return fetchJSON(`/api/employee/${request.payload.id}/add-key`, parameters);
};

export const unbindKeyService = (request) => {
	const parameters = {
		method: 'PUT',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		}
	};

	return fetchJSON(`/api/employee/${request.payload.id}/personal-test/${request.payload.testId}/cancel`, parameters);
};

export const sendEmployeeInvitationKey = (data) => {
	const parameters = {
		method: 'POST',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON(`/api/employee/${data.id}/test-instance/${data.testId}`, parameters);
};
