import { getToken } from '../utils/cookieToken';
import { fetchJSON } from '../utils/fetchJSON';

export const createReportGroupService = (request) => {
	const {
		employeeTestReport: employee_test_report_requests,
		groupReportName
	} = request;

	const parameters = {
		method: 'POST',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ employee_test_report_requests, groupReportName })
	};

	return fetchJSON('/api/group/report/personal-test', parameters);
};

export const shareReportService = (request) => {
	const {
		email,
		employeeTestReport
	} = request;

	const parameters = {
		method: 'PUT',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ sharingEmployeeValues: employeeTestReport, accountEmailList: [email] })
	};

	return fetchJSON('/api/sharing', parameters);
};

export const unShareReportService = (request) => {
	const parameters = {
		method: 'PUT',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(request)
	};

	return fetchJSON('/api/sharing/remove', parameters);
};

export const getAllTestService = () => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON('/api/personality-test', parameters);
};

export const getAllReportsGroupService = () => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON('/api/group/report', parameters);
};


export const deleteReportGroupService = (id) => {
	const parameters = {
		method: 'DELETE',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON(`/api/group/report/${id}`, parameters);
};

export const deleteReportIndividualService = (data) => {
	const parameters = {
		method: 'DELETE',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON(`/api/employee/${data.employeeId}/${data.testInstanceId}`, parameters);
};

export const updateReportGroupService = (request) => {
	const parameters = {
		method: 'PUT',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ name: request.name })
	};

	return fetchJSON(`/api/group/report/${request.id}`, parameters);
};
