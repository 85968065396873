import { logoutUserAction } from '../modules/auth';

export default ({ dispatch }) => next => (action) => {
	const { payload } = action;

	if (payload && payload.status >= 400) {
		const { status } = payload;

		if (status === 401) {
			dispatch(logoutUserAction());
		}
	}
	return next(action);
};
