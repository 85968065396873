import { fromJS } from 'immutable';
import {
	REQUEST,
	SUCCESS,
	ERROR,
	RESET_ERROR,
	GET_LICENSE_COUNT,
	GET_LICENSE_HISTORY,
	GET_LICENSE_USED,
} from '../../store/constants';

const resetError = type => ({
	type: type + RESET_ERROR
});

const getLicenseCountAction = () => ({
	type: GET_LICENSE_COUNT,
});

const getLicenseHistoryAction = () => ({
	type: GET_LICENSE_HISTORY,
});

const getLicenseUsedAction = () => ({
	type: GET_LICENSE_USED,
});

const initialState = fromJS({
	isFetching: {},
	error: {},
	licenseCount: null,
	licenseHistory: null,
	licenseUsed: null,
});

const actionHandlers = {
	[GET_LICENSE_COUNT + REQUEST]: state => state
		.setIn(['isFetching', 'licenseCount'], true)
		.deleteIn(['error', 'licenseCount']),
	[GET_LICENSE_COUNT + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'licenseCount'], false)
			.set('licenseCount', fromJS(payload));
	},
	[GET_LICENSE_COUNT + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'licenseCount'], false)
			.setIn(['error', 'licenseCount'], fromJS(payload));
	},
	[GET_LICENSE_HISTORY + REQUEST]: state => state
		.setIn(['isFetching', 'licenseHistory'], true)
		.deleteIn(['error', 'licenseHistory']),
	[GET_LICENSE_HISTORY + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'licenseHistory'], false)
			.set('licenseHistory', fromJS(payload));
	},
	[GET_LICENSE_HISTORY + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'licenseHistory'], false)
			.setIn(['error', 'licenseHistory'], fromJS(payload));
	},
	[GET_LICENSE_USED + REQUEST]: state => state
		.setIn(['isFetching', 'licenseUsed'], true)
		.deleteIn(['error', 'licenseUsed']),
	[GET_LICENSE_USED + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'licenseUsed'], false)
			.set('licenseUsed', fromJS(payload));
	},
	[GET_LICENSE_USED + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'licenseUsed'], false)
			.setIn(['error', 'licenseUsed'], fromJS(payload));
	},
};

function reducer(state = initialState, action) {
	const handler = actionHandlers[action.type];
	return handler ? handler(state, action) : state;
}

export {
	resetError,
	getLicenseCountAction,
	getLicenseHistoryAction,
	getLicenseUsedAction,
	actionHandlers,
};

export default reducer;
