import { fetchJSON } from '../utils/fetchJSON';
import { getToken } from '../utils/cookieToken';

export const getLicenseCountService = () => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON('/api/license/organization-count', parameters);
};

export const getLicenseHistoryService = () => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON('/api/license/organization-transactional', parameters);
};

export const getLicenseUsedService = () => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken(),
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON('/api/license/organization-used', parameters);
};
