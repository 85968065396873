import React from 'react';
import { Provider } from 'react-redux';
import ConnectedIntlProvider from '../ConnectedIntlProvider';
import store from '../../store/createStore';

const App = () => (
	<Provider store={store}>
		<ConnectedIntlProvider />
	</Provider>
);

export default App;
