import { fromJS, Map } from 'immutable';
import {
	REQUEST,
	SUCCESS,
	ERROR,
	RANGE,
	GET_ALL_GROUP,
	CREATE_GROUP,
	DELETE_GROUP,
	UPDATE_GROUP,
	ADD_EMPLOYEE_TO_GROUP,
	GET_ALL_EMPLOYEE,
	CREATE_EMPLOYEE,
	CREATE_REFERENCE_PROFLE,
	DELETE_EMPLOYEE,
	RESTORE_EMPLOYEE,
	UPDATE_EMPLOYEE,
	RESET_ERROR,
	RESET_STRUCTURE,
	DOWNLOAD_STRUCTURE,
	CREATE_TEST_EMPLOYEE,
	LOADING,
	ERROR_LOADING,
	LOADING_MAIL,
	ERROR_LOADING_MAIL,
	GET_ALL_REMOVED_EMPLOYEE,
	GET_ALL_REFERENCE_PROFILE,
	BIND_KEY,
	UNBIND_KEY,
	REMOVE_EMPLOYEES_FINALLY,
	DELETE_REFERENCE_PROFILE,
	CHANGE_SORT_TYPE
} from '../../store/constants';

const resetErrorAction = type => ({
	type: type + RESET_ERROR
});

const getAllGroupAction = () => ({
	type: GET_ALL_GROUP
});

const createGroupAction = groupData => ({
	type: CREATE_GROUP,
	payload: groupData
});

const deleteGroupAction = groupId => ({
	type: DELETE_GROUP,
	payload: groupId
});

const updateGroupAction = groupInfo => ({
	type: UPDATE_GROUP,
	payload: groupInfo
});

const addEmployeesToGroupAction = (groupId, employeeList) => ({
	type: ADD_EMPLOYEE_TO_GROUP,
	payload: {
		id: groupId,
		body: employeeList
	}
});

const deleteEmployerAction = employerId => ({
	type: DELETE_EMPLOYEE,
	payload: employerId
});

const removeEmployeesFinallyAction = data => ({
	type: REMOVE_EMPLOYEES_FINALLY,
	payload: data
});

const deleteReferenceProfileAction = data => ({
	type: DELETE_REFERENCE_PROFILE,
	payload: data
});

const restoreEmployerAction = employerId => ({
	type: RESTORE_EMPLOYEE,
	payload: employerId
});

const getAllEmployeeAction = data => ({
	type: GET_ALL_EMPLOYEE,
	payload: data
});

const getAllRemovedEmployeeAction = () => ({
	type: GET_ALL_REMOVED_EMPLOYEE
});

const getAllReferenceProfilesAction = () => ({
	type: GET_ALL_REFERENCE_PROFILE
});

const loadingAction = (id, isLoading, isDownloaded) => ({
	type: LOADING,
	payload: { id, isLoading, isDownloaded }
});

const errorLoadingAction = (id, message) => ({
	type: ERROR_LOADING,
	payload: { id, message }
});

const loadingMailAction = (id, isLoading, isDownloaded) => ({
	type: LOADING_MAIL,
	payload: { id, isLoading, isDownloaded }
});

const errorLoadingMailAction = (id, message) => ({
	type: ERROR_LOADING_MAIL,
	payload: { id, message }
});

const createEmployerAction = employerData => ({
	type: CREATE_EMPLOYEE,
	payload: employerData
});

const createReferenceProfileAction = profile => ({
	type: CREATE_REFERENCE_PROFLE,
	payload: profile
});

const updateEmployerAction = (id, data) => ({
	type: UPDATE_EMPLOYEE,
	payload: {
		id,
		data
	}
});

const downloadStructureAction = type => ({
	type: DOWNLOAD_STRUCTURE,
	payload: type
});

const createTestEmployeeAction = (id, testId) => ({
	type: CREATE_TEST_EMPLOYEE,
	payload: {
		id,
		testId
	}
});

const bindKeyAction = (id, key) => ({
	type: BIND_KEY,
	payload: {
		id,
		key
	}
});

const unbindKeyAction = (id, testId) => ({
	type: UNBIND_KEY,
	payload: {
		id,
		testId
	}
});

const changeSortTypeAction = ({ sort, order }) => ({
	type: CHANGE_SORT_TYPE,
	payload: {
		sort, order
	}
});

const initialState = fromJS({
	isFetching: {},
	error: {},
	groups: null,
	employees: {
		content: []
	},
	loaders: null,
	loadersMail: null,
	sort: {
		sort: 'email',
		order: 'asc'
	}
});

const actionHandlers = {
	[CHANGE_SORT_TYPE]: (state, action) => state
		.set('sort', { ...action.payload }),
	[GET_ALL_GROUP + REQUEST]: state => state
		.setIn(['isFetching', 'groups'], true)
		.deleteIn(['error', 'groups']),
	[GET_ALL_GROUP + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'groups'], false)
			.set('groups', fromJS(payload));
	},
	[GET_ALL_GROUP + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'groups'], false)
			.setIn(['error', 'groups'], fromJS(payload));
	},
	[CREATE_GROUP + REQUEST]: state => state
		.setIn(['isFetching', 'createGroup'], true)
		.deleteIn(['error', 'createGroup']),
	[CREATE_GROUP + SUCCESS]: state => (
		state.setIn(['isFetching', 'createGroup'], false)
	),
	[CREATE_GROUP + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'createGroup'], false)
			.setIn(['error', 'createGroup'], fromJS(payload));
	},
	[CREATE_GROUP + RESET_ERROR]: state => (
		state.deleteIn(['error', 'createGroup'])
	),
	[DELETE_GROUP + REQUEST]: state => state
		.setIn(['isFetching', 'deleteGroup'], true)
		.deleteIn(['error', 'deleteGroup']),
	[DELETE_GROUP + SUCCESS]: state => (
		state.setIn(['isFetching', 'deleteGroup'], false)
	),
	[DELETE_GROUP + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'deleteGroup'], false)
			.setIn(['error', 'deleteGroup'], fromJS(payload));
	},
	[DELETE_GROUP + RESET_ERROR]: state => state
		.deleteIn(['error', 'deleteGroup']),
	[UPDATE_GROUP + REQUEST]: state => state
		.setIn(['isFetching', 'updateGroup'], true)
		.deleteIn(['error', 'updateGroup']),
	[UPDATE_GROUP + SUCCESS]: state => (
		state.setIn(['isFetching', 'updateGroup'], false)
	),
	[UPDATE_GROUP + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'updateGroup'], false)
			.setIn(['error', 'updateGroup'], fromJS(payload));
	},
	[ADD_EMPLOYEE_TO_GROUP + REQUEST]: state => state
		.setIn(['isFetching', 'addEmployeeToGroup'], true)
		.deleteIn(['error', 'addEmployeeToGroup']),
	[ADD_EMPLOYEE_TO_GROUP + SUCCESS]: state => (
		state.setIn(['isFetching', 'addEmployeeToGroup'], false)
	),
	[ADD_EMPLOYEE_TO_GROUP + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'addEmployeeToGroup'], false)
			.setIn(['error', 'addEmployeeToGroup'], fromJS(payload));
	},
	[LOADING]: (state, action) => {
		const { payload } = action;
		const loaders = state.get('loaders');
		const {
			id,
			isLoading,
			isDownloaded
		} = payload;

		const _loaders =
			loaders.update(
				loaders.findIndex(
					item => item.get('id') === id
				),
				loader => loader.set('loading', isLoading).set('downloaded', isDownloaded)
			);

		return state
			.set('loaders', _loaders);
	},
	[ERROR_LOADING]: (state, action) => {
		const { payload } = action;
		const loaders = state.get('loaders');
		const {
			id,
			message
		} = payload;

		const _loaders =
			loaders.update(
				loaders.findIndex(
					item => item.get('id') === id
				),
				loader => loader.setIn(['error', 'status'], true).setIn(['error', 'message'], message)
			);

		return state
			.set('loaders', _loaders);
	},
	[LOADING_MAIL]: (state, action) => {
		const { payload } = action;
		const loadersMail = state.get('loadersMail');
		const {
			id,
			isLoading,
			isDownloaded
		} = payload;

		const _loadersMail =
			loadersMail.update(
				loadersMail.findIndex(
					item => item.get('id') === id
				),
				loader => loader.set('loading', isLoading).set('downloaded', isDownloaded)
			);

		return state
			.set('loadersMail', _loadersMail);
	},
	[ERROR_LOADING_MAIL]: (state, action) => {
		const { payload } = action;
		const loadersMail = state.get('loadersMail');
		const {
			id,
			message
		} = payload;

		const _loadersMail =
			loadersMail.update(
				loadersMail.findIndex(
					item => item.get('id') === id
				),
				loader => loader.setIn(['error', 'status'], true).setIn(['error', 'message'], message)
			);

		return state
			.set('loadersMail', _loadersMail);
	},
	[GET_ALL_EMPLOYEE + REQUEST]: state => state
		.setIn(['isFetching', 'employees'], true)
		.deleteIn(['error', 'employees']),
	[GET_ALL_EMPLOYEE + SUCCESS]: (state, action) => {
		const { payload } = action;

		const loaders = [];
		const loadersMail = [];

		payload.content.forEach((employee) => {
			employee.test_view.forEach((item) => {
				if (item.state === 'PASSED') {
					const obj = {
						id: item.test_instance_id,
						loading: false,
						downloaded: false,
						error: {
							message: null,
							status: false
						}
					};

					loadersMail.push(obj);
					loaders.push(obj);
				}
			});
		});


		return state
			.setIn(['isFetching', 'employees'], false)
			.set('loaders', fromJS(loaders))
			.set('loadersMail', fromJS(loadersMail))
			.set('employees', fromJS(payload));
	},
	[GET_ALL_EMPLOYEE + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'employees'], false)
			.setIn(['error', 'employees'], fromJS(payload));
	},
	[GET_ALL_REMOVED_EMPLOYEE + REQUEST]: state => state
		.setIn(['isFetching', 'removedEmployees'], true)
		.deleteIn(['error', 'removedEmployees']),
	[GET_ALL_REMOVED_EMPLOYEE + SUCCESS]: (state, action) => {
		const { payload } = action;

		return state
			.setIn(['isFetching', 'removedEmployees'], false)
			.set('removedEmployees', fromJS(payload));
	},
	[GET_ALL_REMOVED_EMPLOYEE + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'removedEmployees'], false)
			.setIn(['error', 'removedEmployees'], fromJS(payload));
	},
	[GET_ALL_REFERENCE_PROFILE + REQUEST]: state => state
		.setIn(['isFetching', 'referenceProfile'], true)
		.deleteIn(['error', 'referenceProfile']),
	[GET_ALL_REFERENCE_PROFILE + SUCCESS]: (state, action) => {
		const { payload } = action;

		return state
			.setIn(['isFetching', 'referenceProfile'], false)
			.set('referenceProfile', payload);
	},
	[GET_ALL_REFERENCE_PROFILE + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'referenceProfile'], false)
			.setIn(['error', 'referenceProfile'], fromJS(payload));
	},
	[CREATE_EMPLOYEE + REQUEST]: state => state
		.setIn(['isFetching', 'createEmployer'], true)
		.deleteIn(['error', 'groups']),
	[CREATE_EMPLOYEE + SUCCESS]: state => (
		state.setIn(['isFetching', 'createEmployer'], false)
	),
	[CREATE_EMPLOYEE + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'createEmployer'], false)
			.setIn(['error', 'createEmployer'], fromJS(payload));
	},
	[CREATE_EMPLOYEE + RESET_ERROR]: state => (
		state
			.deleteIn(['error', 'createEmployer'])
	),
	[CREATE_REFERENCE_PROFLE + REQUEST]: state => state
		.setIn(['isFetching', 'createReferenceProfile'], true)
		.deleteIn(['error', 'groups']),
	[CREATE_REFERENCE_PROFLE + SUCCESS]: state => (
		state.setIn(['isFetching', 'createReferenceProfile'], false)
	),
	[CREATE_REFERENCE_PROFLE + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'createReferenceProfile'], false)
			.setIn(['error', 'createReferenceProfile'], fromJS(payload));
	},
	[CREATE_REFERENCE_PROFLE + RESET_ERROR]: state => (
		state
			.deleteIn(['error', 'createReferenceProfile'])
	),
	[DELETE_EMPLOYEE + REQUEST]: state => state
		.setIn(['isFetching', 'deleteEmployee'], true)
		.deleteIn(['error', 'deleteEmployee']),
	[DELETE_EMPLOYEE + SUCCESS]: state => (
		state.setIn(['isFetching', 'deleteEmployee'], false)
	),
	[DELETE_EMPLOYEE + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'deleteEmployee'], false)
			.setIn(['error', 'deleteEmployee'], fromJS(payload));
	},
	[REMOVE_EMPLOYEES_FINALLY + REQUEST]: state => state
		.setIn(['isFetching', 'deleteEmployee'], true)
		.deleteIn(['error', 'deleteEmployee']),
	[REMOVE_EMPLOYEES_FINALLY + SUCCESS]: state => (
		state.setIn(['isFetching', 'deleteEmployee'], false)
	),
	[REMOVE_EMPLOYEES_FINALLY + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'deleteEmployee'], false)
			.setIn(['error', 'deleteEmployee'], fromJS(payload));
	},
	[DELETE_REFERENCE_PROFILE + REQUEST]: state => state
		.setIn(['isFetching', 'deleteReferenceProfile'], true)
		.deleteIn(['error', 'deleteReferenceProfile']),
	[DELETE_REFERENCE_PROFILE + SUCCESS]: state => (
		state.setIn(['isFetching', 'deleteReferenceProfile'], false)
	),
	[DELETE_REFERENCE_PROFILE + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'deleteReferenceProfile'], false)
			.setIn(['error', 'deleteReferenceProfile'], fromJS(payload));
	},
	[RESTORE_EMPLOYEE + REQUEST]: state => state
		.setIn(['isFetching', 'restoreEmployee'], true)
		.deleteIn(['error', 'restoreEmployee']),
	[RESTORE_EMPLOYEE + SUCCESS]: state => (
		state.setIn(['isFetching', 'restoreEmployee'], false)
	),
	[RESTORE_EMPLOYEE + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'restoreEmployee'], false)
			.setIn(['error', 'restoreEmployee'], fromJS(payload));
	},
	[UPDATE_EMPLOYEE + REQUEST]: state => state
		.setIn(['isFetching', 'updateEmployer'], true)
		.deleteIn(['error', 'updateEmployer']),
	[UPDATE_EMPLOYEE + SUCCESS]: state => (
		state.setIn(['isFetching', 'updateEmployer'], false)
	),
	[UPDATE_EMPLOYEE + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'updateEmployer'], false)
			.setIn(['error', 'updateEmployer'], fromJS(payload));
	},
	[RESET_STRUCTURE]: state => state
		.set('isFetching', Map({}))
		.set('error', Map({}))
		.set('groups', null)
		.set('employees', null),
	[CREATE_TEST_EMPLOYEE + REQUEST]: state => state
		.setIn(['isFetching', 'createTestEmployee'], true)
		.setIn(['isFetching', 'createTestEmployeeFullRange'], true)
		.deleteIn(['error', 'createTestEmployee']),
	[CREATE_TEST_EMPLOYEE + SUCCESS]: state => (
		state.setIn(['isFetching', 'createTestEmployee'], false)
	),
	[CREATE_TEST_EMPLOYEE + RANGE]: state => (
		state.setIn(['isFetching', 'createTestEmployeeFullRange'], false)
	),
	[CREATE_TEST_EMPLOYEE + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'createTestEmployee'], false)
			.setIn(['isFetching', 'createTestEmployeeFullRange'], false)
			.setIn(['error', 'createTestEmployee'], fromJS(payload));
	},
	[CREATE_TEST_EMPLOYEE + RESET_ERROR]: state => (
		state
			.deleteIn(['error', 'createTestEmployee'])
	),
	[BIND_KEY + REQUEST]: state => state
		.setIn(['isFetching', 'bindKey'], true)
		.deleteIn(['error', 'bindKey']),
	[BIND_KEY + SUCCESS]: state => (
		state.setIn(['isFetching', 'bindKey'], false)
	),
	[BIND_KEY + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'bindKey'], false)
			.setIn(['error', 'bindKey'], fromJS(payload));
	},
	[UNBIND_KEY + REQUEST]: state => state
		.setIn(['isFetching', 'unbindKey'], true)
		.deleteIn(['error', 'unbindKey']),
	[UNBIND_KEY + SUCCESS]: state => (
		state.setIn(['isFetching', 'unbindKey'], false)
	),
	[UNBIND_KEY + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.setIn(['isFetching', 'unbindKey'], false)
			.setIn(['error', 'unbindKey'], fromJS(payload));
	}
};

function reducer(state = initialState, action) {
	const handler = actionHandlers[action.type];
	return handler ? handler(state, action) : state;
}

export {
	getAllEmployeeAction,
	getAllGroupAction,
	createEmployerAction,
	resetErrorAction,
	deleteEmployerAction,
	updateEmployerAction,
	downloadStructureAction,
	addEmployeesToGroupAction,
	bindKeyAction,
	unbindKeyAction,
	createGroupAction,
	createReferenceProfileAction,
	createTestEmployeeAction,
	deleteGroupAction,
	deleteReferenceProfileAction,
	restoreEmployerAction,
	getAllReferenceProfilesAction,
	getAllRemovedEmployeeAction,
	removeEmployeesFinallyAction,
	updateGroupAction,
	loadingAction,
	loadingMailAction,
	errorLoadingAction,
	errorLoadingMailAction,
	changeSortTypeAction,
	actionHandlers,
};

export default reducer;
