import { getToken } from '../utils/cookieToken';
import { fetchJSON } from '../utils/fetchJSON';

export default () => {
	const parameters = {
		method: 'GET',
		headers: {
			...getToken()
		}
	};

	return fetchJSON('/api/order/stripe/config', parameters);
};
