import React from 'react';
import { Router } from 'react-router-dom';
import { IntlProvider, addLocaleData } from 'react-intl';
import ukLocaleData from 'react-intl/locale-data/uk';
import ruLocaleData from 'react-intl/locale-data/ru';
import enLocaleData from 'react-intl/locale-data/en';
import ScrollRestoration from '../../components/ScrollRestoration';
import CoreLayout from '../../layouts/CoreLayout/CoreLayout';
import history from '../../store/history';
import messages from '../../lang';

addLocaleData([...ukLocaleData, ...ruLocaleData, ...enLocaleData]);
const DEFAULT_LANG = 'ru';

const ConnectedIntlProvider = () => (
	<IntlProvider
		messages={messages[DEFAULT_LANG]}
		locale={DEFAULT_LANG}
		key={DEFAULT_LANG}
	>
		<Router history={history}>
			<ScrollRestoration>
				<CoreLayout />
			</ScrollRestoration>
		</Router>
	</IntlProvider>
);

export default ConnectedIntlProvider;
