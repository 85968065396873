import { fetchJSON } from '../utils/fetchJSON';
import { getToken } from '../utils/cookieToken';

export const getLanguagesService = async () => {
	const parameters = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
	};

	return fetchJSON('/api/localization/languages', parameters);
};

export const registerUserService = (request) => {
	const parameters = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(request.payload),
	};

	return fetchJSON('/api/account', parameters);
};

export const loginUserService = (request) => {
	const parameters = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(request.payload),
	};

	return fetchJSON('/api/account/auth', parameters);
};

export const confirmTokenService = (request) => {
	const parameters = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(request.payload),
	};

	return fetchJSON(
		`/api/account/confirm/token/validate?token=${request.payload.token}`,
		parameters
	);
};

export const forgotPasswordService = (request) => {
	const parameters = {
		method: 'POST',
		headers: {
			// 'Authorization': 'Bearer',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(request.payload),
	};

	return fetchJSON('/api/account/password/reset', parameters);
};

export const setNewPasswordService = (request) => {
	const parameters = {
		method: 'POST',
		headers: {
			// 'Authorization': 'Bearer',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(request.payload.password),
	};

	return fetchJSON(
		`/api/account/password?token=${request.payload.token}`,
		parameters
	);
};

export const changeUserPasswordService = (request) => {
	const parameters = {
		method: 'POST',
		headers: {
			...getToken(),
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(request.payload),
	};

	return fetchJSON('/api/account/password/change', parameters);
};
