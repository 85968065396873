import { put, call } from 'redux-saga/effects';
import * as types from '../store/constants';

import {
	getAllEmployeeService,
	getSkillsFilterService,
	findSkillsFilterService,
	findIdSkillsFilterService,
	createSkillsFilterService,
	updateSkillsFilterService,
	deleteSkillsFilterService,
	getAllSkillsService,
} from '../services/skillsService';

export function* getAllEmployeeSkillsSaga(data) {
	yield put({ type: `${types.GET_ALL_EMPLOYEE_SKILLS}${types.REQUEST}` });
	try {
		const responseAllEmployeeSkills = yield call(getAllEmployeeService, data.payload);
		yield put({ type: `${types.GET_ALL_EMPLOYEE_SKILLS}${types.SUCCESS}`, payload: responseAllEmployeeSkills });
	} catch (error) {
		yield put({ type: `${types.GET_ALL_EMPLOYEE_SKILLS}${types.ERROR}`, payload: error });
	}
}

export function* getSkillsFilterSaga() {
	yield put({ type: `${types.GET_SKILLS_FILTER}${types.REQUEST}` });
	try {
		const responseSkillsFilter = yield call(getSkillsFilterService);
		yield put({ type: `${types.GET_SKILLS_FILTER}${types.SUCCESS}`, payload: responseSkillsFilter });
	} catch (error) {
		yield put({ type: `${types.GET_SKILLS_FILTER}${types.ERROR}`, payload: error });
	}
}

export function* findSkillsFilterSaga(data) {
	yield put({ type: `${types.FIND_SKILLS_FILTER}${types.REQUEST}` });
	try {
		const responseSkillsFilter = yield call(findSkillsFilterService, data.payload);
		yield put({ type: `${types.FIND_SKILLS_FILTER}${types.SUCCESS}`, payload: responseSkillsFilter });
		yield put({
			type: types.MODAL_CLOSE,
			payload: {
				modalType: types.MODAL_SEARCH_SKILLS
			}
		});
	} catch (error) {
		yield put({ type: `${types.FIND_SKILLS_FILTER}${types.ERROR}`, payload: error });
	}
}

export function* findIdSkillsFilterSaga(data) {
	yield put({ type: `${types.FIND_ID_SKILLS_FILTER}${types.REQUEST}` });
	try {
		const responseIdSkillsFilter = yield call(findIdSkillsFilterService, data.payload);
		yield put({ type: `${types.FIND_ID_SKILLS_FILTER}${types.SUCCESS}`, payload: responseIdSkillsFilter });
	} catch (error) {
		yield put({ type: `${types.FIND_ID_SKILLS_FILTER}${types.ERROR}`, payload: error });
	}
}

// TODO: запрос на создание фильтра присутсвует в folder /ajax, убрать обработку тут
export function* createSkillsFilterSaga(data) {
	yield put({ type: `${types.CREATE_SKILLS_FILTER}${types.REQUEST}` });
	try {
		const responseCreateSkillsFilter = yield call(createSkillsFilterService, data.payload);
		yield getSkillsFilterSaga();
		yield put({ type: `${types.CREATE_SKILLS_FILTER}${types.SUCCESS}`, payload: responseCreateSkillsFilter });
	} catch (error) {
		yield put({ type: `${types.CREATE_SKILLS_FILTER}${types.SUCCESS}`, payload: error });
	}
}

export function* updateSkillsFilterSaga(data) {
	yield put({ type: `${types.UPDATE_SKILLS_FILTER}${types.REQUEST}` });
	try {
		const responseUpdateSkillsFilter = yield call(updateSkillsFilterService, data.payload);
		yield put({ type: `${types.UPDATE_SKILLS_FILTER}${types.SUCCESS}`, payload: responseUpdateSkillsFilter });
	} catch (error) {
		yield put({ type: `${types.UPDATE_SKILLS_FILTER}${types.ERROR}`, payload: error });
	}
}

export function* deleteSkillsFilterSaga(data) {
	yield put({ type: `${types.DELETE_SKILLS_FILTER}${types.REQUEST}` });
	try {
		const responseDeleteSkillsFilter = yield call(deleteSkillsFilterService, data.payload);
		yield getSkillsFilterSaga();
		yield put({ type: `${types.DELETE_SKILLS_FILTER}${types.SUCCESS}`, payload: responseDeleteSkillsFilter });
	} catch (error) {
		yield put({ type: `${types.DELETE_SKILLS_FILTER}${types.ERROR}`, payload: error });
	}
}

export function* getAllSkillsSaga() {
	yield put({ type: `${types.GET_ALL_SKILLS}${types.REQUEST}` });
	try {
		const responseAllSkills = yield call(getAllSkillsService);
		yield put({ type: `${types.GET_ALL_SKILLS}${types.SUCCESS}`, payload: responseAllSkills });
	} catch (error) {
		yield put({ type: `${types.GET_ALL_SKILLS}${types.ERROR}`, payload: error });
	}
}
