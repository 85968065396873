export default () => ({
	getItem: (field) => {
		const data = localStorage.getItem(field);
		if (data !== undefined) {
			return data;
		}
		return undefined;
	},
	setItem: (field, data) => {
		localStorage.setItem(field, data);
	},
	removeItem: (field) => {
		localStorage.removeItem(field);
	}
});
