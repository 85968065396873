import { put, call } from 'redux-saga/effects';
import saveFile from '../utils/fileSave';
import * as types from '../store/constants';
import { successCodesEmployer } from '../static/fixtures';
import {
	successCodes,
	errorCodesGroup
} from '../static/errors';
import {
	createEmployerService,
	createReferenceProfileService,
	createGroupService,
	deleteGroupService,
	updateGroupService,
	getAllGroupService,
	addEmployeesToGroupService,
	getAllEmployeeService,
	getAllRemovedEmployeeService,
	getAllReferenceProfilesService,
	deleteEmployerService,
	removeEmployeesFinallyService,
	deleteReferenceProfileService,
	restoreEmployerService,
	updateEmployerService,
	downloadStructureService,
	createTestEmployeeService,
	bindKeyService,
	unbindKeyService,
	sendEmployeeInvitationKey
} from '../services/structureService';

import {
	getAccountLicenseCountSaga
} from './userSaga';
import history from '../store/history';

export function* getAllGroup() {
	yield put({ type: `${types.GET_ALL_GROUP}${types.REQUEST}` });
	try {
		const responseAllGroup = yield call(getAllGroupService);
		yield put({ type: `${types.GET_ALL_GROUP}${types.SUCCESS}`, payload: responseAllGroup });
	} catch (error) {
		yield put({ type: `${types.GET_ALL_GROUP}${types.ERROR}`, payload: error });
	}
}

export function* getAllEmployee(data = {}) {
	yield put({ type: `${types.GET_ALL_EMPLOYEE}${types.REQUEST}` });
	try {
		const responseAllEmployee = yield call(getAllEmployeeService, data.payload);
		yield put({ type: `${types.GET_ALL_EMPLOYEE}${types.SUCCESS}`, payload: responseAllEmployee });
	} catch (error) {
		yield put({ type: `${types.GET_ALL_EMPLOYEE}${types.ERROR}`, payload: error });
	}
}

export function* getAllRemovedEmployee() {
	yield put({ type: `${types.GET_ALL_REMOVED_EMPLOYEE}${types.REQUEST}` });
	try {
		const responseAllEmployee = yield call(getAllRemovedEmployeeService);
		yield put({ type: `${types.GET_ALL_REMOVED_EMPLOYEE}${types.SUCCESS}`, payload: responseAllEmployee });
	} catch (error) {
		yield put({ type: `${types.GET_ALL_REMOVED_EMPLOYEE}${types.ERROR}`, payload: error });
	}
}

export function* getAllReferenceProfiles() {
	yield put({ type: `${types.GET_ALL_REFERENCE_PROFILE}${types.REQUEST}` });
	try {
		const responseAllReferenceProfiles = yield call(getAllReferenceProfilesService);

		yield put({
			type: `${types.GET_ALL_REFERENCE_PROFILE}${types.SUCCESS}`,
			payload: {
				templates: responseAllReferenceProfiles[0],
				data: responseAllReferenceProfiles[1]
			}
		});
	} catch (error) {
		yield put({ type: `${types.GET_ALL_REFERENCE_PROFILE}${types.ERROR}`, payload: error });
	}
}

export function* createGroup(payload) {
	yield put({ type: `${types.CREATE_GROUP}${types.REQUEST}` });
	try {
		yield call(createGroupService, payload);
		yield put({ type: `${types.CREATE_GROUP}${types.SUCCESS}` });
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.ACCOUNT_MODAL_CREATE_GROUP,
				modalData: { step: types.MODAL_STEP_TWO }
			}
		});
		yield getAllGroup();
	} catch (error) {
		yield put({ type: `${types.CREATE_GROUP}${types.ERROR}`, payload: error });
	}
}

export function* deleteGroup(payload) {
	yield put({ type: `${types.DELETE_GROUP}${types.REQUEST}` });
	try {
		yield call(deleteGroupService, payload);
		yield put({ type: `${types.DELETE_GROUP}${types.SUCCESS}` });
		yield getAllGroup();
		yield call(history.push, '/account/structure/groups');
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.MODAL_GROUP_DELETE,
				modalData: { step: types.MODAL_STEP_TWO }
			}
		});
	} catch (error) {
		yield put({ type: `${types.DELETE_GROUP}${types.ERROR}`, payload: error });
	}
}

export function* updateGroup(payload) {
	yield put({ type: `${types.UPDATE_GROUP}${types.REQUEST}` });
	try {
		yield call(updateGroupService, payload);
		yield put({ type: `${types.UPDATE_GROUP}${types.SUCCESS}` });
		yield getAllGroup();
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.MODAL_SUCCESS,
				modalData: { message: successCodes.updateGroup.message }
			}
		});
	} catch (error) {
		if (errorCodesGroup[error.status]) {
			yield put({
				type: types.MODAL_OPEN,
				payload: {
					modalType: types.MODAL_SUCCESS,
					modalData: { message: errorCodesGroup[error.status].message }
				}
			});
		}
		yield put({ type: `${types.UPDATE_GROUP}${types.ERROR}`, payload: error });
	}
}

export function* addEmployeesToGroup(payload) {
	yield put({ type: `${types.ADD_EMPLOYEE_TO_GROUP}${types.REQUEST}` });
	try {
		yield call(addEmployeesToGroupService, payload);
		yield put({ type: `${types.ADD_EMPLOYEE_TO_GROUP}${types.SUCCESS}` });
		yield call(getAllEmployee);
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.MODAL_ADD_EMPLOYEE_TO_GROUP,
				modalData: { step: types.MODAL_STEP_TWO }
			}
		});
	} catch (error) {
		yield put({ type: `${types.ADD_EMPLOYEE_TO_GROUP}${types.ERROR}`, payload: error });
	}
}

export function* createEmployer(payload) {
	// TODO[front]: need to divide the request to delete the employee used in two places
	yield put({ type: `${types.CREATE_EMPLOYEE}${types.REQUEST}` });
	try {
		const responseEmployer = yield call(createEmployerService, payload);
		yield put({ type: `${types.CREATE_EMPLOYEE}${types.SUCCESS}`, payload: responseEmployer });
		yield call(getAllEmployee);
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.CREATE_MODAL_EMPLOYEE,
				modalData: types.MODAL_STEP_TWO
			}
		});
	} catch (error) {
		yield put({ type: `${types.CREATE_EMPLOYEE}${types.ERROR}`, payload: error });
	}
}

export function* createReferenceProfile(payload) {
	yield put({ type: `${types.CREATE_REFERENCE_PROFLE}${types.REQUEST}` });
	try {
		const responseEmployer = yield call(createReferenceProfileService, payload);
		yield put({ type: `${types.CREATE_REFERENCE_PROFLE}${types.SUCCESS}`, payload: responseEmployer });
		yield call(getAllReferenceProfiles);
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.MODAL_REFERENCE_PROFILE,
				modalData: { step: types.MODAL_STEP_TWO }
			}
		});
	} catch (error) {
		yield put({ type: `${types.CREATE_REFERENCE_PROFLE}${types.ERROR}`, payload: error });
	}
}

export function* deleteEmployer(payload) {
	yield put({ type: `${types.DELETE_EMPLOYEE}${types.REQUEST}` });
	try {
		const responseEmployer = yield call(deleteEmployerService, payload);
		yield put({ type: `${types.DELETE_EMPLOYEE}${types.SUCCESS}`, payload: responseEmployer });
		yield call(getAllEmployee);
		yield call(getAllRemovedEmployee);
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.MODAL_EMPLOYEE_DELETE,
				modalData: { step: types.MODAL_STEP_TWO }
			}
		});
		yield call(history.push, '/account/structure/employees');
	} catch (error) {
		yield put({ type: `${types.DELETE_EMPLOYEE}${types.ERROR}`, payload: error });
	}
}

export function* removeEmployeesFinally(payload) {
	yield put({ type: `${types.REMOVE_EMPLOYEES_FINALLY}${types.REQUEST}` });
	try {
		const responseEmployer = yield call(removeEmployeesFinallyService, payload);
		yield put({ type: `${types.REMOVE_EMPLOYEES_FINALLY}${types.SUCCESS}`, payload: responseEmployer });
		yield call(getAllEmployee);
		yield call(getAllRemovedEmployee);
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.MODAL_REMOVE_EMPLOYEES_FINALLY,
				modalData: { step: types.MODAL_STEP_TWO }
			}
		});
	} catch (error) {
		yield put({ type: `${types.REMOVE_EMPLOYEES_FINALLY}${types.ERROR}`, payload: error });
	}
}

export function* deleteReferenceProfile(payload) {
	yield put({ type: `${types.DELETE_REFERENCE_PROFILE}${types.REQUEST}` });
	try {
		const responseEmployer = yield call(deleteReferenceProfileService, payload);
		yield put({ type: `${types.DELETE_REFERENCE_PROFILE}${types.SUCCESS}`, payload: responseEmployer });
		yield call(getAllReferenceProfiles);
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.MODAL_REFERENCE_PROFILE_DELETE,
				modalData: { step: types.MODAL_STEP_TWO }
			}
		});
	} catch (error) {
		yield put({ type: `${types.DELETE_REFERENCE_PROFILE}${types.ERROR}`, payload: error });
	}
}

export function* restoreEmployer(payload) {
	yield put({ type: `${types.RESTORE_EMPLOYEE}${types.REQUEST}` });
	try {
		const responseEmployer = yield call(restoreEmployerService, payload);
		yield put({ type: `${types.RESTORE_EMPLOYEE}${types.SUCCESS}`, payload: responseEmployer });
		yield call(getAllEmployee);
		yield call(getAllRemovedEmployee);
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.MODAL_EMPLOYEE_RESTORE,
				modalData: { step: types.MODAL_STEP_TWO }
			}
		});
		yield call(history.push, '/account/structure/employees-removed');
	} catch (error) {
		yield put({ type: `${types.RESTORE_EMPLOYEE}${types.ERROR}`, payload: error });
	}
}

export function* updateEmployer(payload) {
	yield put({ type: `${types.UPDATE_EMPLOYEE}${types.REQUEST}` });
	try {
		const responseEmployer = yield call(updateEmployerService, payload);
		yield put({ type: `${types.UPDATE_EMPLOYEE}${types.SUCCESS}`, payload: responseEmployer });
		yield call(getAllEmployee);
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.MODAL_SUCCESS,
				modalData: { message: successCodesEmployer.message }
			}
		});
	} catch (error) {
		yield put({ type: `${types.UPDATE_EMPLOYEE}${types.ERROR}`, payload: error });
	}
}

export function* downloadStructure(payload) {
	yield put({ type: `${types.DOWNLOAD_STRUCTURE}${types.REQUEST}` });
	try {
		const response = yield call(downloadStructureService, payload);
		saveFile(response);
		yield put({ type: `${types.DOWNLOAD_STRUCTURE}${types.SUCCESS}` });
	} catch (error) {
		yield put({ type: `${types.DOWNLOAD_STRUCTURE}${types.ERROR}`, payload: error });
	}
}

export function* createTestEmployeeSaga(payload) {
	yield put({ type: `${types.CREATE_TEST_EMPLOYEE}${types.REQUEST}` });
	const { payload: { id } } = payload;
	try {
		const response = yield call(createTestEmployeeService, payload);
		yield put({ type: `${types.CREATE_TEST_EMPLOYEE}${types.SUCCESS}` });
		yield call(sendEmployeeInvitationKey, { id, testId: response.test_instance_id });
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.MODAL_CREATE_TEST_EMPLOYEE,
				modalData: { step: types.MODAL_STEP_TWO }
			}
		});
		yield put({ type: `${types.CREATE_TEST_EMPLOYEE}${types.RANGE}` });
		yield getAccountLicenseCountSaga();
		yield call(getAllEmployee);
	} catch (error) {
		yield put({ type: `${types.CREATE_TEST_EMPLOYEE}${types.ERROR}`, payload: error });
	}
}

export function* bindKeySaga(payload) {
	yield put({ type: `${types.BIND_KEY}${types.REQUEST}` });

	try {
		yield call(bindKeyService, payload);
		yield put({ type: `${types.BIND_KEY}${types.SUCCESS}` });
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.MODAL_BIND_KEY,
				modalData: { step: types.MODAL_STEP_TWO }
			}
		});
		yield put({ type: `${types.BIND_KEY}${types.RANGE}` });
		yield call(getAllEmployee);
	} catch (error) {
		yield put({ type: `${types.BIND_KEY}${types.ERROR}`, payload: error });
	}
}

export function* unbindKeySaga(payload) {
	yield put({ type: `${types.UNBIND_KEY}${types.REQUEST}` });

	try {
		yield call(unbindKeyService, payload);
		yield put({ type: `${types.UNBIND_KEY}${types.SUCCESS}` });
		yield put({
			type: types.MODAL_OPEN,
			payload: {
				modalType: types.MODAL_UNBIND_KEY,
				modalData: { step: types.MODAL_STEP_TWO }
			}
		});
		yield put({ type: `${types.UNBIND_KEY}${types.RANGE}` });
		yield call(getAllEmployee);
	} catch (error) {
		yield put({ type: `${types.UNBIND_KEY}${types.ERROR}`, payload: error });
	}
}
