import { fromJS } from 'immutable';
import {
	REQUEST,
	SUCCESS,
	ERROR,
	RESET_ERROR,
	CREATE_PAYPAL_PAYMENT,
	CANCEL_PAYPAL_PAYMENT,
	COMPLETE_PAYPAL_PAYMENT,

	CREATE_STRIPE_PAYMENT,
	CANCEL_STRIPE_PAYMENT,
	COMPLETE_STRIPE_PAYMENT,
	CREATE_STRIPE_PAYMENT_WITHOUT_AUTH,
	CANCEL_STRIPE_PAYMENT_WITHOUT_AUTH,
	COMPLETE_STRIPE_PAYMENT_WITHOUT_AUTH,
	GET_STRIPE_CONFIG,

	CREATE_PAYPAL_PAYMENT_WITHOUT_AUTH,
	CANCEL_PAYPAL_PAYMENT_WITHOUT_AUTH,
	COMPLETE_PAYPAL_PAYMENT_WITHOUT_AUTH,
	PROMO_CODE_PAYMENT
} from '../../store/constants';

export const resetErrorAction = () => ({
	type: RESET_ERROR
});

export const createPaypalPaymentAction = payload => ({
	type: CREATE_PAYPAL_PAYMENT,
	payload
});

export const cancelPaypalPaymentAction = payload => ({
	type: CANCEL_PAYPAL_PAYMENT,
	payload
});

export const completePaypalPaymentAction = payload => ({
	type: COMPLETE_PAYPAL_PAYMENT,
	payload
});

export const completePaypalPaymentWithoutAuthAction = payload => ({
	type: COMPLETE_PAYPAL_PAYMENT_WITHOUT_AUTH,
	payload
});

export const createPaypalPaymentWithoutAuthAction = payload => ({
	type: CREATE_PAYPAL_PAYMENT_WITHOUT_AUTH,
	payload
});

export const cancelPaypalPaymentWithoutAuthAction = payload => ({
	type: CANCEL_PAYPAL_PAYMENT_WITHOUT_AUTH,
	payload
});

export const checkPromoCodeAction = payload => ({
	type: PROMO_CODE_PAYMENT,
	payload
});

export const createStripePaymentAction = payload => ({
	type: CREATE_STRIPE_PAYMENT,
	payload
});

export const cancelStripePaymentAction = payload => ({
	type: CANCEL_STRIPE_PAYMENT,
	payload
});

export const completeStripePaymentAction = payload => ({
	type: COMPLETE_STRIPE_PAYMENT,
	payload
});

export const completeStripePaymentWithoutAuthAction = payload => ({
	type: COMPLETE_STRIPE_PAYMENT_WITHOUT_AUTH,
	payload
});

export const createStripePaymentWithoutAuthAction = payload => ({
	type: CREATE_STRIPE_PAYMENT_WITHOUT_AUTH,
	payload
});

export const cancelStripePaymentWithoutAuthAction = payload => ({
	type: CANCEL_STRIPE_PAYMENT_WITHOUT_AUTH,
	payload
});

export const getStripeConfigAction = payload => ({
	type: GET_STRIPE_CONFIG,
	payload
});

const initialState = fromJS({
	isFetching: false,
	isFetchingPromo: false,
	promoCode: {},
	error: {}
});

const actionHandlers = {
	[RESET_ERROR]: state => state
		.set('error', null)
		.set('createPaypal', null)
		.set('completePaypal', null)
		.set('cancelPaypal', null)
		.set('createStripe', null)
		.set('completeStripe', null)
		.set('cancelStripe', null),

	[CREATE_PAYPAL_PAYMENT + REQUEST]: state => state
		.set('isFetching', true)
		.delete('error', false),
	[CREATE_PAYPAL_PAYMENT + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.set('createPaypal', fromJS(payload));
	},
	[CREATE_PAYPAL_PAYMENT + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.setIn(['error', 'createPaypal'], fromJS(payload));
	},

	[CANCEL_PAYPAL_PAYMENT + REQUEST]: state => state
		.set('isFetching', true)
		.delete('error', false),
	[CANCEL_PAYPAL_PAYMENT + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.set('cancelPaypal', fromJS(payload));
	},
	[CANCEL_PAYPAL_PAYMENT + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.setIn(['error', 'cancelPaypal'], fromJS(payload));
	},

	[COMPLETE_PAYPAL_PAYMENT + REQUEST]: state => state
		.set('isFetching', true)
		.delete('error', false),
	[COMPLETE_PAYPAL_PAYMENT + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.set('completePaypal', fromJS(payload));
	},
	[COMPLETE_PAYPAL_PAYMENT + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.setIn(['error', 'completePaypal'], fromJS(payload));
	},

	// < ---- Stripe service

	[CREATE_STRIPE_PAYMENT + REQUEST]: state => state
		.set('isFetching', true)
		.delete('error', false),
	[CREATE_STRIPE_PAYMENT + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.set('createStripe', fromJS(payload));
	},
	[CREATE_STRIPE_PAYMENT + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.setIn(['error', 'createStripe'], fromJS(payload));
	},

	[CANCEL_STRIPE_PAYMENT + REQUEST]: state => state
		.set('isFetching', true)
		.delete('error', false),
	[CANCEL_STRIPE_PAYMENT + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.set('cancelStripe', fromJS(payload));
	},
	[CANCEL_STRIPE_PAYMENT + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.setIn(['error', 'cancelStripe'], fromJS(payload));
	},

	[COMPLETE_STRIPE_PAYMENT + REQUEST]: state => state
		.set('isFetching', true)
		.delete('error', false),
	[COMPLETE_STRIPE_PAYMENT + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.set('completeStripe', fromJS(payload));
	},
	[COMPLETE_STRIPE_PAYMENT + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.setIn(['error', 'completeStripe'], fromJS(payload));
	},

	[GET_STRIPE_CONFIG + REQUEST]: state => state
		.set('isFetching', true)
		.delete('error', false),
	[GET_STRIPE_CONFIG + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.set('stripeConfig', fromJS(payload));
	},
	[GET_STRIPE_CONFIG + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.setIn(['error', 'stripeConfig'], fromJS(payload));
	},

	[CREATE_STRIPE_PAYMENT_WITHOUT_AUTH + REQUEST]: state => state
		.set('isFetching', true)
		.delete('error', false),
	[CREATE_STRIPE_PAYMENT_WITHOUT_AUTH + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.set('createStripe', fromJS(payload));
	},
	[CREATE_STRIPE_PAYMENT_WITHOUT_AUTH + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.setIn(['error', 'createStripe'], fromJS(payload));
	},

	[CANCEL_STRIPE_PAYMENT_WITHOUT_AUTH + REQUEST]: state => state
		.set('isFetching', true)
		.delete('error', false),
	[CANCEL_STRIPE_PAYMENT_WITHOUT_AUTH + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.set('cancelStripe', fromJS(payload));
	},
	[CANCEL_STRIPE_PAYMENT_WITHOUT_AUTH + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.setIn(['error', 'cancelStripe'], fromJS(payload));
	},

	[COMPLETE_STRIPE_PAYMENT_WITHOUT_AUTH + REQUEST]: state => state
		.set('isFetching', true)
		.delete('error', false),
	[COMPLETE_STRIPE_PAYMENT_WITHOUT_AUTH + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.set('completeStripe', fromJS(payload));
	},
	[COMPLETE_STRIPE_PAYMENT_WITHOUT_AUTH + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.setIn(['error', 'completeStripe'], fromJS(payload));
	},

	// ----> Stripe service

	// payment without auth

	[CREATE_PAYPAL_PAYMENT_WITHOUT_AUTH + REQUEST]: state => state
		.set('isFetching', true)
		.delete('error', false),
	[CREATE_PAYPAL_PAYMENT_WITHOUT_AUTH + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.set('createPaypal', fromJS(payload));
	},
	[CREATE_PAYPAL_PAYMENT_WITHOUT_AUTH + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.setIn(['error', 'createPaypal'], fromJS(payload));
	},

	[CANCEL_PAYPAL_PAYMENT_WITHOUT_AUTH + REQUEST]: state => state
		.set('isFetching', true)
		.delete('error', false),
	[CANCEL_PAYPAL_PAYMENT_WITHOUT_AUTH + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.set('cancelPaypal', fromJS(payload));
	},
	[CANCEL_PAYPAL_PAYMENT_WITHOUT_AUTH + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.setIn(['error', 'cancelPaypal'], fromJS(payload));
	},

	[COMPLETE_PAYPAL_PAYMENT_WITHOUT_AUTH + REQUEST]: state => state
		.set('isFetching', true)
		.delete('error', false),
	[COMPLETE_PAYPAL_PAYMENT_WITHOUT_AUTH + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.set('completePaypal', fromJS(payload));
	},
	[COMPLETE_PAYPAL_PAYMENT_WITHOUT_AUTH + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetching', false)
			.setIn(['error', 'completePaypal'], fromJS(payload));
	},

	[PROMO_CODE_PAYMENT + REQUEST]: state => state
		.set('isFetchingPromo', true)
		.set('promoCode', fromJS({}))
		.deleteIn(['error', 'promoCode']),
	[PROMO_CODE_PAYMENT + SUCCESS]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetchingPromo', false)
			.deleteIn(['error', 'promoCode'])
			.set('promoCode', fromJS(payload));
	},
	[PROMO_CODE_PAYMENT + ERROR]: (state, action) => {
		const { payload } = action;
		return state
			.set('isFetchingPromo', false)
			.set('promoCode', fromJS({}))
			.setIn(['error', 'promoCode'], fromJS(payload));
	}
};

function reducer(state = initialState, action) {
	const handler = actionHandlers[action.type];
	return handler ? handler(state, action) : state;
}

const actionCreators = {
	actionHandlers,
	createPaypalPaymentAction,
	cancelPaypalPaymentAction,
	completePaypalPaymentAction,
	createPaypalPaymentWithoutAuthAction,
	cancelPaypalPaymentWithoutAuthAction,
	completePaypalPaymentWithoutAuthAction,

	createStripePaymentAction,
	cancelStripePaymentAction,
	completeStripePaymentAction,
	createStripePaymentWithoutAuthAction,
	cancelStripePaymentWithoutAuthAction,
	completeStripePaymentWithoutAuthAction,
	getStripeConfigAction,

	resetErrorAction
};

export { actionCreators };

export default reducer;
